import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import ReactLoading from 'react-loading';
import axios from 'axios';
import { myConfig } from '../config'
const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);
class LoginHeader extends Component {
    constructor() {
        super()
        this.state = {
            isHidden: true,
            redirectToReferrer: false,
            open: false,
            selectedFile: null, loaded: 0,
            message: null,
            title: null,
            loading: false,
            titleValid: false,
            messageValid: false,
            formErrors: { title: '', message: '' },

            formValid: false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);


    }
    handleClickOpen = () => {
        this.setState({
            open: true,
            titleValid: false,
            messageValid: false,
            formErrors: { title: '', message: '' },
            loaded:0,
            formValid: false
        });
    };
    handleClose = () => {
        this.setState({ open: false ,
            titleValid: false,
            messageValid: false,
            formErrors: { title: '', message: '' },
            loaded:0,
            formValid: false
        });
    };
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    handleInputChange(data) {


        this.setState({
            message: data
        }, 
        () => { this.validateField('message', data) });



    }
    handleTextChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value
        }, 
        () => { this.validateField(name, value) });

    }
    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }
    handleClick(event) {
        event.preventDefault()
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        this.toggleHidden()
        this.setState({ redirectToReferrer: true });

    }
    renderRedirect = () => {
        if (this.state.redirectToReferrer) {
            return <Redirect to='/login' />
        }
    }
    handleUpload = () => {
        var apiBaseUrl = myConfig.apiUrl;
        const data = new FormData()
        if (this.state.selectedFile)
            data.append('attachment', this.state.selectedFile, this.state.selectedFile.name)
            data.append('question_title', this.state.title)
        data.append('question_txt', this.state.message)
        data.append('user_id', localStorage.getItem("user_id"))
        this.setState({ loading: true });
        axios
            .post(apiBaseUrl + "/questions", data, {
                onUploadProgress: ProgressEvent => {
                   
                    this.setState({
                      loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                   
                  })
                },
            })
            .then(res => {
                alert("Question submitted successfully");
                this.setState({
                    loaded: 100,
                    loading: false,
                    open:false
                })
                this.props.onQnupdate()
            })

    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleValid = this.state.titleValid;
        let messageValid = this.state.messageValid;

        switch (fieldName) {
            case 'title':

                titleValid = (value !== '') ? true : false;
                fieldValidationErrors.title = titleValid ? '' : 'First Name id is invalid';
                break;
            case 'message':

                messageValid = (value !== '') ? true : false;
                fieldValidationErrors.message = messageValid ? '' : 'Last Name id is invalid';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,

            titleValid: titleValid,
            messageValid: messageValid
        }, this.validateForm);


    }

    validateForm() {

        this.setState({ formValid: this.state.titleValid && this.state.messageValid });


    }
    render() {
        console.log(localStorage.getItem('user_type'))

        const Child = () => (
            <div className="menu-icon  " >
                <div className="bar1">
                    <ul>
                        <li><a href="/my-profile" >My Account</a></li>
                        <li><a href="#" onClick={this.handleClick}>Logout</a></li>
                    </ul></div>

            </div>
        )
        var loginlink;
        var profilelink;
        var ask_qn;
        if (!localStorage.getItem('isLoggedin')) {
            loginlink = <li className="header-menu"><a href="/login">Login  </a></li>
            ask_qn = null
        }
        else {
            ask_qn = <li className="header-menu ask-menu"><a href="#" onClick={this.handleClickOpen}>Ask Question</a>
            </li>
            loginlink = <li onClick={this.toggleHidden.bind(this)} className="login-menu header-menu"><a href="#"  > <span className="glyphicon glyphicon-user"></span> </a></li>
        }

        return (
            <div className="header-box ">
                {this.renderRedirect()}
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        <b>Ask Question</b>
                    </DialogTitle>
                    <DialogContent>
                        <div className="md-dialog-content">
                            <div className="row" >

                                <div className="col-md-12">
                                    <label htmlFor="firstname">Title <span className="mandatory">
                                        *</span></label>
                                    <input type="text" name="title" onChange={this.handleTextChange} className="form-control" autoComplete="off"
                                        style={{ width: '100%' }} />
                                    {(!this.state.titleValid) ? (
                                        <span

                                            className="field_error" style={{ color: 'red' }} >This field is required</span>
                                    ) :
                                        (null)
                                    }
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <label htmlFor="firstname">Description <span className="mandatory">
                                        *</span></label>
                                    <ReactSummernote
                                        onChange={this.handleInputChange}
                                        options={{

                                            height: 250,
                                            dialogsInBody: true,
                                            toolbar: [

                                                ['font', ['bold', 'underline', 'clear']],

                                                ['fontsize', ['fontsize']],

                                                ['insert', ['picture']],

                                            ]
                                        }}

                                    />
                                    {(!this.state.messageValid ) ? (
                                        <span
                                            className="field_error" style={{ color: 'red' }}>This field is required</span>
                                    ) :
                                        (null)
                                    }
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-3">
                                    <label htmlFor="attachment" style={{ marginTop: '5px' }}>Attachment</label>
                                </div>
                                <div className="col-md-3">
                                    <label className="browseBtn"><label>  <input
                                        type="file" id="attachment" onChange={this.handleselectedFile}

                                        multiple title="Select Documents" style={{ width: '100%' }}
                                        accept=".png,.jpeg,.jpg,.doc,.pdf,.docx,.xls,.xlsx" />
                                    </label> </label>
                                </div>
                            </div>


                            <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>

                                <div className="col-md-12">
                               
                                            <button className="signupbtn text-center" disabled={!this.state.formValid} onClick={this.handleUpload} style={{ width: '150px' }} ng-click="ask()">
                                                Submit</button>
                              
                                    <div id="result">{(this.state.loaded == 100) ? 'Question submitted successfully' : ''} </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
                <div className="container-fluid">
                    <div className="row">
                        <div className="header-box-con-fluidtent">
                            <div className="col-xs-12  col-sm-12 col-md-12 col-lg-6 main-logo">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 supported_by">
                                    <a href="/"><img className="img-responsive" style={{ width: '50%' }} src="../images/pumexlogolarge.png" /></a>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 header_text">
                                    <p>Discussion Forum</p>
                                </div>
                            </div>

                            {!this.state.isHidden && <Child />}


                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 menu-items">
                                <ul>

                                    <li className=" active header-menu"><Link to="/searchresults">Question & Answers</Link>
                                    </li>
                                    {(localStorage.getItem('user_type') === 'Admin') && <li className="header-menu"><a href="/users">Users</a></li>}


                                    {ask_qn}

                                    {loginlink}


                                </ul>

                            </div>
                            <div className="clearfix"></div>

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default LoginHeader;
