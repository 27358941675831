import React, { Component } from 'react';
import { Link } from "react-router-dom";
class UseransRows extends Component {
    createMarkup(data) {
        return {__html: data};
      }
      dateDiff(date_val){
        var date_now=new Date()
        var date_value=new Date(date_val)
      var d = Math.abs(date_now-date_value) / 1000;                           // delta
      var r = {};                                                                // result
      var s = {                                                                  // structure
          year: 31536000,
          month: 2592000,
          //week: 604800, // uncomment row to ignore
          day: 86400,   // feel free to add your own row
          hour: 3600,
          minute: 60,
          second: 1
      };
      
      Object.keys(s).forEach(function(key){
          r[key] = Math.floor(d / s[key]);
          d -= r[key] * s[key];
      });
      
      // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
      return r;
    }
    render() {
        var datediff=this.dateDiff(this.props.data.date_created);
        return (
            <div className="question_container"
            ng-repeat="question in ctrl.userQuestionsList">

            <div className="answer-listing-section question_inner_container">
                <ul className="parent-comment myUl">
                    <li className="myUl">
                        <div className="col-xs-12 col-sm-10 col-md-11">
                            <div className="comment-content">
                                <div className="name-date">
                                    <div className="date-section">
                                        <h5>
                                        <span >
                                            {datediff.year}years</span> <span >
                                            {datediff.month}months</span> <span
                                                >
                                                {datediff.day}days</span> <span
                                                >
                                                {datediff.hour}hrs</span> <span
                                                >
                                                {datediff.minute}mins</span> <span
                                                >
                                                {datediff.second}secs</span> ago
                                                    </h5>
                                    </div>

                                </div>
                                <div className="clear"></div>
                                <div className="comment-description font_bold">
                                <Link to={'/questions/'+this.props.data.qn_id}><p
                                        style={{ fontSize: '20px', color: '#448ae8', fontFamily: 'Dupla-Bold' }}
                                        dangerouslySetInnerHTML={this.createMarkup(this.props.data.ans_txt)}></p></Link> <label
                                        ng-bind-html="question.ContentText | emoji "> </label>
                                </div>

                                <div className="relytocomment-section">
                                    <h6>
                                       <br /> <a href="javascript:void(0)">{this.props.data.likes}
                                                        Vote(s)<span></span>
                                        </a>
                                    </h6>
                                </div>
                                <div className="clear"></div>
                            </div>

                        </div>
                        <div className="clear"></div>

                    </li>
                </ul>
            </div>
        </div>
        );
    }
}

export default UseransRows;
