import React, { Component } from 'react';
import Signup from './Signup';
import Login from './Login';
import TopQuestions from './TopQuestions';
import TopUsers from './TopUsers';
import { Router, Route, Switch ,Redirect,withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import ReactLoading from 'react-loading';
import axios from 'axios';
import { myConfig } from '../config'
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
        open: false,
        selectedFile: null, loaded: 0,
            message: null,
            title:null,
       loading:false,
       search:null,
       titleValid:false,
       messageValid:false,
       formErrors: {title:'',message:''},

     formValid: false
    }
    //localStorage.removeItem("isLoggedin");
    //localStorage.removeItem("user_name");
    //localStorage.removeItem("user_id");
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handlesearchClick = this.handlesearchClick.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
}
componentWillReceiveProps(newProps) {    
  //console.log('Home Component WILL RECIEVE PROPS!')
  //console.log(newProps)
  this.setState({questions:newProps.questions})
}
handleClickOpen = () => {
  this.setState({
    open: true,
    titleValid: false,
    messageValid: false,
    formErrors: { title: '', message: '' },
    loaded:0,
    formValid: false
  });
};

handleClose = () => {
  this.setState({ open: false,
    titleValid: false,
    messageValid: false,
    formErrors: { title: '', message: '' },
    loaded:0,
    formValid: false
   });
};
handleselectedFile = event => {
  this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
  })
}
handleInputChange(data) {


  this.setState({
      message: data
  }, 
  () => { this.validateField('message', data) });

  

}
handlesearchClick(event) {
  event.preventDefault();
  let path = `searchresults/${this.state.search}`;
  this.props.history.push(path);


}
handleKeyPress = (event) => {

  if (event.key == 'Enter') {
      event.preventDefault()
      let path = `/searchresults/${this.state.search}`;
      this.props.history.push(path);

  }
}

handleTextChange(event) {
  
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  
  const name = target.name;

  this.setState({
      [name]: value
  }, 
  () => { this.validateField(name, value) });
  
}
handleUpload = () => {
  var apiBaseUrl = myConfig.apiUrl;
  const data = new FormData()
  if(this.state.selectedFile)
  data.append('attachment', this.state.selectedFile, this.state.selectedFile.name)
  data.append('question_title', this.state.title)
  data.append('question_txt', this.state.message)
  data.append('user_id', localStorage.getItem("user_id"))
  this.setState({loading:true});
  axios
      .post(apiBaseUrl + "/questions", data, {
          onUploadProgress: ProgressEvent => {
              
             
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
             
            })
          },
      })
      .then(res => {
        alert("Question submitted successfully");
          this.setState({
            loaded: 100,
            loading:false,
            open:false
        })
        this.props.onQnupdate()
      })

}
componentDidMount() {
  //console.log("Home Did Mount")
if(this.props.questions){
  //console.log(this.props.questions);
  this.setState({questions:this.props.questions})

}
}

validateField(fieldName, value) {
  let fieldValidationErrors = this.state.formErrors;
  let titleValid = this.state.titleValid;
  let messageValid = this.state.messageValid;
 
  switch(fieldName) {
    case 'title':

      titleValid = (value!=='')? true:false;
      fieldValidationErrors.title = titleValid ? '' : 'First Name id is invalid';
      break;
      case 'message':

      messageValid = (value!=='')? true:false;
      fieldValidationErrors.message = messageValid ? '' : 'Last Name id is invalid';
      break;

    default:
      break;
  }

  this.setState({formErrors: fieldValidationErrors,
               
                  titleValid:titleValid,
                  messageValid:messageValid
                }, this.validateForm);
              

}

validateForm() {

  this.setState({formValid: this.state.titleValid && this.state.messageValid });
  
  
}
  render() {

    return (
        <div>
       <Switch>
          <Route exact path="/" component={Signup} />
          <Route exact path="/login" component={Login} />
        </Switch>
        
        <section id="find-questions-section">
		<h2>Find Questions & Answers</h2>
		<div className="search-container">
			<form id="question-form">

				<div
					className="form-group col-xs-12 col-md-4 col-md-offset-4 find-question">
					<input type="text" className="search-button"  onChange={this.handleTextChange}
						placeholder="Find questions , answers etc" name="search" onKeyPress={this.handleKeyPress} />
					<button value="button" className="search_button" onClick={this.handlesearchClick}
						>
						<span className="glyphicon glyphicon-search" aria-hidden="true"></span>
					</button>
				</div>
				<div className="col-md-2">
        {(localStorage.getItem('isLoggedin')) && (
					<button className="signupbtn" type="button" onClick={this.handleClickOpen} style={{width: '200px'}}
						>Ask Question</button>)}
				</div>
			</form>
		</div>

	</section>
        <section id="latest-question-section">
          <div className="container">
            <div className="row">
              <div className="latest-top-ranked">

                <TopQuestions questions={this.state.questions} />

                <TopUsers />
                <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth={'md'}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            <b>Ask Question</b>
          </DialogTitle>
          <DialogContent>
          <div  className="md-dialog-content">
		<div className="row" >

			<div className="col-md-12">
      <label htmlFor="title">Title <span className="mandatory">
						*</span></label>
				<input type="text" name="title" id="title" onChange={this.handleTextChange}  className="form-control" autoComplete="off"
					style={{width: '100%'}} /> 
          {(!this.state.titleValid) ? (
          <span
				
					className="field_error" style={{color:'red'}} >This field is required</span>
          ):
          (null)
          }
			</div>
		</div>
		<div className="row" >
			<div className="col-md-12">
      <label htmlFor="firstname">Description <span className="mandatory">
						*</span></label>
      <ReactSummernote 
onChange={this.handleInputChange}
options={{
    
    height: 250,
    dialogsInBody: true,
    toolbar: [
       
        ['font', ['bold', 'underline', 'clear']],
       
        ['fontsize', ['fontsize']],
       
        ['insert', [ 'picture']],
       
    ]
}}

/>
{(!this.state.messageValid) ? (
				<span 
					className="field_error" style={{color:'red'}}>This field is required</span>
):
(null)
}
			</div>
		</div>
		<div className="row" >
			<div className="col-md-3">
				<label htmlFor="attachment" style={{marginTop: '5px'}}>Attachment</label>
			</div>
			<div className="col-md-3">
				<label className="browseBtn"><label>  <input
						type="file" id="attachment" onChange={this.handleselectedFile}
						
						multiple title="Select Documents" style={{width: '100%'}}
						accept=".png,.jpeg,.jpg,.doc,.pdf,.docx,.xls,.xlsx" />
				</label> </label>
			</div>
		</div>


		<div className="row" style={{marginTop: '2%', marginBottom: '2%' }}>
		
			<div className="col-md-12">

     
				<button className="signupbtn text-center" disabled={!this.state.formValid}  onClick={this.handleUpload} style={{width: '150px'}} ng-click="ask()">
					Submit</button>
       
          <div id="result">{(this.state.loaded==100)? 'Question submitted successfully':''} </div>
			</div>
		</div>
	</div>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
              </div>
            </div>
          </div>

        </section>
        </div>
    );
  }
}

export default withRouter(Home);
