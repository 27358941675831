import React, { Component } from 'react';
import Header from './components/Header';
import Home from './components/Home';
import QuestionDetail from './components/QuestionDetail';
import Footer from './components/Footer';
import QuestionAns from './components/QuestionAns';
import AskQuestions from './components/AskQuestions';
import Questions from './components/Questions';
import MyProfile from './components/MyProfile';
import EditProfile from './components/EditProfile';
import UserDetails from './components/UserDetails';
import SearchResults from './components/SearchResults';
import UsersList from './components/UsersList';
import axios from 'axios';
import { myConfig } from './config'
import { Route, Switch } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
  this.state={questions:[]}
  this.loadQuestions = this.loadQuestions.bind(this);
  //this.loadQuestions()
}
loadQuestions(){
  axios.get(`${myConfig.apiUrl}/questions/10`, {
    params: {
        sort: 1
    }
})
    .then(res => {
        const data = res.data;
        this.setState({ questions:data });
    })
}
componentDidMount() {
  console.log("App Did Mount")
  this.loadQuestions()
}
  render() {
    return (
      <div className="App">
     
      
      <Header onQnupdate={this.loadQuestions} />
        
        <Switch>
        <Route path='/all_questions' component={Questions} />
        <Route path='/ask-question' component={AskQuestions} />
        <Route path='/my-profile' component={MyProfile} />
        <Route path='/edit-profile' component={EditProfile} />
        <Route path='/questions/:qn_id' component={QuestionAns} />
        <Route path="/questiondetail" component={QuestionDetail} />
        <Route path='/user/:user_id' component={UserDetails} />
        <Route path='/searchresults/:search_key' component={SearchResults} />
        <Route path='/searchresults' component={SearchResults} />
        <Route path='/users' component={UsersList} />
          <Route path="/" render={(props) => <Home {...props} onQnupdate={this.loadQuestions}   questions={this.state.questions} />} />
          <Route path="/login" render={(props) => <Home {...props} onQnupdate={this.loadQuestions}   questions={this.state.questions} />} />

        </Switch>
        
        <Footer />
      </div>
    );
  }
}

export default App;
