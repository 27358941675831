import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { myConfig } from '../config'
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            checked: false,
            email: '',
            password: '',
            email_rr: true,
            password_err: true,
            login_error: false,
            redirectToReferrer: false,
            emailValid: false,
            paswordValid: false,
            formErrors: { fullname: '', email: '', password: '' },
        }
        //localStorage.removeItem("isLoggedin");
        //localStorage.removeItem("user_name");
        //localStorage.removeItem("user_id");
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value) });

        if (this.state.email !== '') {
            this.setState({ email_rr: false })
        }
        else {
            this.setState({ email_rr: true })
        }

    }
    handleKeyPress = (event) => {
console.log("Pressed:"+event.key)
        if (event.key == 'Enter') {
            event.preventDefault()
            if (this.state.formValid)
                this.handleClick(event)

        }
    }
    handleClick(event) {

        var apiBaseUrl = myConfig.apiUrl;
        var self = this;
        console.log(this.state);
        var payload = "user_name=" + this.state.email + "&password=" + this.state.password;


        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        axios.post(apiBaseUrl + '/login', payload, config)
            .then(function (response) {
                console.log(response);

                if (response.data.status == true) {
                    console.log("login successfull");
                    localStorage.setItem("isLoggedin", true);
                    localStorage.setItem("user_name", self.state.email);
                    localStorage.setItem("user_id", response.data.user_id);
                    localStorage.setItem("user_type", response.data.user_type);
                    //window.location.reload(); 
                    self.setState({ redirectToReferrer: true });

                    //self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                }
                else {
                    console.log(response);
                    self.setState({ login_error: true })

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;


        switch (fieldName) {

            case 'email':

                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'Email id is invalid';
                break;
            case 'password':

                passwordValid = (value !== '') ? true : false;
                fieldValidationErrors.password = passwordValid ? '' : 'Password is invalid';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,


            emailValid: emailValid,
            passwordValid: passwordValid
        }, this.validateForm);


    }

    validateForm() {

        this.setState({ formValid: this.state.emailValid && this.state.passwordValid });


    }
    render() {

        const uNstatus = (this.state.email === '') ? true : false;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const emailValid = (pattern.test(this.state.email)) ? true : false;
        const Pwdstatus = (this.state.password === '') ? true : false;
        const loginerr = this.state.login_error;
        var errmg = <span className="field_error"></span>
        if (loginerr) {
            errmg = <span className="field_error">The
        email id or password is incorrect</span>;
        } else {
            errmg = <span className="field_error"></span>;
        }
        if (this.state.redirectToReferrer) {
            return <Redirect to={'/'} />;
        }
        else {
            return (
                <section id="main-banner-section">

                    <div className="main-banner-first-section">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12 col-sm-6 col-md-8 software-testing-heading">
                                    <h1>Software Testing from Road Block to Opportunity</h1>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>

                                    <input type="button" className="read-more-button" value="Read More" />
                                </div>
                                <div className=" col-xs-12 col-sm-6 col-md-4 signup-section"
                                >
                                    <form className="form-floating card" name="forms.LoginForm" noValidate
                                    >
                                        <h2>Login</h2>
                                        <div className="login-email floating-labels">

                                            <input type="email" name="email" id="login-user-email" onKeyPress={this.handleKeyPress}
                                                placeholder="Email Address"
                                                autoComplete="off" required onInput={this.handleInputChange} />

                                            {(!this.state.emailValid) && (<span className="field_error">Invalid E-mail ID</span>)
                                            }

                                        </div>
                                        <div className="login-password floating-labels">

                                            <input type="password" name="password"

                                                placeholder="Password" autoComplete="off" onKeyPress={this.handleKeyPress} required onInput={this.handleInputChange}
                                            />
                                            {!this.state.passwordValid && (
                                                <span className="field_error">Enter your password</span>
                                            )}

                                        </div>
                                        {errmg}


                                        <button type="button" disabled={!this.state.formValid} className="signupbtn" onClick={this.handleClick}
                                        >Login</button>




                                        <p>
                                            Create An Account? <Link to="/"><span
                                                className="account-login"> Sign Up</span></Link>
                                        </p>
                                    </form>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div className="software-testing-lists">
                        <div className="container">
                            <ul>
                                <li className=" border-right">
                                    <a href="#">Pumex Update Special 2018 - UTRECHT</a></li>
                                <li className="border-right"><a href="#">Software testing from road block to opportunity</a></li>
                                <li className=" border-right"><a href="#">Exploring Testing - A New Hope</a></li>
                                <li className="border-right"><a href="#">The Digital Imperative for Transforming Software</a></li>
                                <li className="user-conference"><a href="#">Pumex User Conference - Pune</a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="clearfix"></div>

                </section>

            );
        }
    }
}

export default Login;
