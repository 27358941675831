import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery'
import './index.css';
import App from './App';
import { Router} from "react-router-dom";
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';
const hist = createBrowserHistory();
window.jQuery = $;
ReactDOM.render(
<Router history={hist}>
<App />
</Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
