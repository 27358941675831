import React, { Component } from 'react';
import axios from 'axios';
import UserRows from './UserRows';
import { myConfig } from '../config'
class TopUsers extends Component {
    constructor() {
        super();
        this.state = {
           data: 
           [
             
           ],
           search_key:''

           
        }
        axios.get(`${myConfig.apiUrl}/users/10`)
        .then(res => {
          const data = res.data;
          this.setState({ data });
        })
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlesearchClick = this.handlesearchClick.bind(this);
     }
     handleTextChange(event) {
  
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        
        const name = target.name;
      
        this.setState({
            [name]: value
        });
        
      }
      handleKeyPress = (event) => {

        if (event.key == 'Enter') {
            event.preventDefault()
            axios.get(`${myConfig.apiUrl}/users/10`, {
                params: {
                    search_key: this.state.search_key
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }
      }
      handlesearchClick(event) {
        event.preventDefault();
     
        axios.get(`${myConfig.apiUrl}/users/10`, {
            params: {
                search_key: this.state.search_key
            }
        })
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })



    }
    render() {
        return (
            <div className="  col-xs-12 col-md-12 col-lg-4">
                <div className="ranked-section">
                    <h2>Active Users</h2>
                    <form id="search-form" name="form" action="" method="post">

                        <div className="form-group col-xs-12 search-section">
                            <input type="text" onChange={this.handleTextChange} onKeyPress={this.handleKeyPress} className="search-text" placeholder="Search" id="search" name="search_key" />
                            <button value="submit" onClick={this.handlesearchClick} className="search_button"><span className="glyphicon glyphicon-search" aria-hidden="true"></span></button>
                        </div>
                    </form>
                    <table>
                        <thead>
                            <tr>
                                <th className="slno-title">SI No</th>
                                <th>Name</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        
                            {this.state.data.map((dtrow, i) => <UserRows key = {i} 
                     data = {dtrow} index={i} />)}
                        </tbody>
                    </table>


                </div>


            </div>

        );
    }
}

export default TopUsers;
