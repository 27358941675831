import React, { Component } from 'react';
import axios from 'axios';
import {  Redirect } from "react-router-dom";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import { myConfig } from '../config'
class AskQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null, loaded: 0,
            message: null
        }
        //localStorage.removeItem("isLoggedin");
        //localStorage.removeItem("user_name");
        //localStorage.removeItem("user_id");
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    handleInputChange(data) {


        this.setState({
            message: data
        });

        console.log(this.state.message);

    }
    handleUpload = () => {
        var apiBaseUrl = myConfig.apiUrl;
        const data = new FormData()
        if(this.state.selectedFile)
        data.append('attachment', this.state.selectedFile, this.state.selectedFile.name)
        data.append('question_txt', this.state.message)
        data.append('question_title', this.state.title)
        data.append('user_id', localStorage.getItem("user_id"))
        axios
            .post(apiBaseUrl + "questions", data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => {
                this.setState({
                    loaded: 100,
                })
                console.log(res.statusText)
            })

    }
    render() {
        if (!localStorage.getItem('isLoggedin')) {
            return <Redirect to={'/login'} />;
        }
        else {
            return (
                <div>
                    <section id="custom-answers-section">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12 col-md-12 col-lg-12">
                                    <div className="answer-message">
                                        <h2>Answers</h2>

                                        <div className=" col-xs-12 col-sm-2 col-md-1 left-img">
                                            <img src="../images/P-img1.jpg" />
                                        </div>
                                        <div className=" col-xs-12 col-sm-10 col-md-11 right-content">

                                            <form id="answer-form" name="form" action="" method="post" className="row">

                                                <div className="form-group">
                                                    <ReactSummernote onChange={this.handleInputChange}

                                                        options={{
                                                            
                                                            height: 250,
                                                            dialogsInBody: true,
                                                            toolbar: [
                                                               
                                                                ['font', ['bold', 'underline', 'clear']],
                                                               
                                                                ['fontsize', ['fontsize']],
                                                               
                                                                ['insert', [ 'picture']],
                                                               
                                                            ]
                                                        }}

                                                    />

                                                    <input type="file" name="fileToUpload" id="fileToUpload" onChange={this.handleselectedFile} />

                                                    <div id="err1" className="err"></div>
                                                </div>
                                                <button type="button" onClick={this.handleUpload} className="btn submit_btn">Submit</button>
                                                <div id="result">{(this.state.loaded==100)? 'Question submitted successfully': ''} </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}

export default AskQuestions;
