import React, { Component } from 'react';

import { Link, Redirect } from "react-router-dom";
import { myConfig } from '../config'
class UserRows extends Component {
    render() {
        return (
            <tr>
            <td className="slno">{eval(this.props.index)+1}</td>
            {localStorage.getItem('isLoggedin')  ? (
            <td><Link to={(localStorage.getItem('user_id')===this.props.data.user_id) ? '/my-profile':'/user/'+this.props.data.user_id }><img style={{width:'63px',height:'63px'}} src={this.props.data.profile_photo!=null ? myConfig.apiUrl+"/get_file/"+this.props.data.profile_photo :"../images/defpic.png"} /><span>{(this.props.data.fullname.length>8) ? (((this.props.data.fullname).substring(0,7)) + '...') : 
            this.props.data.fullname}</span></Link></td>
            ) :
            (<td><img style={{width:'63px',height:'63px'}} src={this.props.data.profile_photo!=null ? myConfig.apiUrl+"/get_file/"+this.props.data.profile_photo :"../images/defpic.png"} /><span>{(this.props.data.fullname.length>8) ? (((this.props.data.fullname).substring(0,7)) + '...') : 
            this.props.data.fullname}</span></td>)
            }
          
        </tr>

        );
    }
}

export default UserRows;
