import React, { Component } from 'react';


class Footer extends Component {
  render() {
    return (
<div className="footer-section">
<p>© 2018-2020 Pumex Infotech.<span>All rights reserved.</span></p>

</div>

    );
  }
}

export default Footer;
