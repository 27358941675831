import React, { Component } from 'react';

import { Link, Redirect } from "react-router-dom";

class UserListRow extends Component {
    render() {
        return (
            <tr >
            <td className="align_center" style={{textAlign: 'center'}}>{eval(this.props.index)+1}</td>
            <td className="align_center" style={{textAlign: 'center'}}>{this.props.data.fullname}</td>
            <td className="align_center" style={{textAlign: 'center'}}>{this.props.data.email_id}</td>
            <td className="align_center" style={{textAlign: 'center'}}>{this.props.data.user_type}</td>
            <td className="align_center" style={{textAlign: 'center'}}>{this.props.data.organization}</td>
            <td className="align_center" style={{textAlign: 'center'}}>{this.props.data.date_added}</td>
            <td className="align_center" style={{width: '20%', textAlign: 'center', paddingLeft: '7.5%', fontSize: '20px'}}>
              <ul className="sub_ul">
                <li><a  title="Edit" href="javascript:void(0)" onClick={() => this.props.editfn(this.props.data.user_id)}><span className="glyphicon glyphicon-edit ng-scope" /></a></li>
                {(this.props.data.user_status=='Active') &&
                (<li><a  title="Deactivate"  href="javascript:void(0)" onClick={() => this.props.deactivateFn(this.props.data.user_id)} ><span className="glyphicon glyphicon-remove-sign ng-scope" style={{color: 'red'}} /></a></li>)}
                {(this.props.data.user_status=='Inactive') &&
                (<li><a  title="Activate"  href="javascript:void(0)" onClick={() => this.props.activateFn(this.props.data.user_id)}  ><span className="glyphicon glyphicon-ok-sign ng-scope" style={{color: 'green'}} /></a></li>)}
              </ul>
            </td>
          </tr>

        );
    }
}

export default UserListRow;
