import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { myConfig } from '../config'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            checked: false,
            email: '',
            password: '',
            fullname: '',
            redirectToReferrer: false,
            fullnameValid: false,
            emailValid: false,
            paswordValid: false,
            
            formErrors: { fullname: '', email: '', password: '' },
            loading: false,
            formValid: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.checkUser = this.checkUser.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value) });
    }
    checkUser() {
        this.setState({loading:true})
        if(this.state.emailValid){
        var email = encodeURIComponent(this.state.email);
        axios.get(`${myConfig.apiUrl}/check_email/${email}`)
            .then(res => {
                const data = res.data;
                
                if(data.status===true){
                    
                    this.setState({emailValid:true,loading:false},this.validateForm)
                }
                else{
                    var formErrors=this.state.formErrors;
                    formErrors.email="Email already exists";
                    this.setState({emailValid:false,loading:false,formErrors:formErrors},this.validateForm)
                }
            })
        }
    }
    handleClick(event) {

        var apiBaseUrl = myConfig.apiUrl;
        var self = this;

        var payload = "email=" + this.state.email + "&password=" + this.state.password + "&fullname=" + this.state.fullname;


        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        //var self = this;
        axios.post(apiBaseUrl + '/signup', payload, config)
            .then(function (response) {
                ;

                if (response.data.status == true) {
                    console.log("Registration successfull");

                    self.setState({ redirectToReferrer: true });

                    //self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                }
                else {

                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let fullnameValid = this.state.fullnameValid;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;


        switch (fieldName) {
            case 'fullname':

                fullnameValid = (value !== '') ? true : false;
                fieldValidationErrors.fullname = fullnameValid ? '' : 'First Name id is invalid';
                break;
            case 'email':

                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'Email id is invalid';
                break;
            case 'password':

                passwordValid = (value !== '') ? true : false;
                fieldValidationErrors.password = passwordValid ? '' : 'Password is invalid';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,

            fullnameValid: fullnameValid,
            emailValid: emailValid,
            passwordValid: passwordValid
        }, this.validateForm);


    }

    validateForm() {

        this.setState({ formValid: this.state.fullnameValid && this.state.emailValid && this.state.passwordValid });


    }
    render() {
        
        const uNstatus = (this.state.email === '') ? true : false;
        const fnstatus = (this.state.fullname === '') ? true : false;
        const Pwdstatus = (this.state.password === '') ? true : false;
        var signups_sect;
        if (!localStorage.getItem('isLoggedin')) {
            signups_sect = <div className=" col-xs-12 col-sm-6 col-md-4 signup-section">
                <h2>Sign Up</h2>
                <form id="sign-up-form" name="form" action="" method="post">
                    <div className="login-username floating-labels">

                        <input type="text" name="fullname" id="login-user-name" value={this.state.fullname}
                            onChange={this.handleInputChange} placeholder="Display Name" autoComplete="off" required />
                        {(!this.state.fullnameValid) && (
                            <span className="field_error">Enter your Name</span>
                        )}
                    </div>
                    <div className="login-email floating-labels">

                        <input type="email" name="email" id="login-user-email" onBlur={this.checkUser} placeholder="Email Address" autoComplete="off" required value={this.state.email}
                            onChange={this.handleInputChange} />
                        {!this.state.emailValid && (
                            <span className="field_error">{this.state.formErrors.email}</span>
                        )}
                    </div>
                    <div className="login-password floating-labels">

                        <input type="password" name="password" id="login-user-password" placeholder="Password" autoComplete="off" required value={this.state.password}
                            onChange={this.handleInputChange} />
                        {!this.state.passwordValid && (
                            <span className="field_error">Enter your password</span>
                        )}
                    </div>
                    <button type="button" disabled={!this.state.formValid} onClick={this.handleClick} className="signupbtn">Sign Up</button>

                    <p>Already have an Account?<Link to="/login"><span className="account-login"> Login</span></Link></p>
                </form>
            </div>
        }
        if (this.state.redirectToReferrer) {
            return <Redirect to={'/login'} />;
        }
        else {
            return (
                <section id="main-banner-section">

                    <div className="main-banner-first-section">
                        <div className="container">
                            <div className="row" style={{ minHeight: '500px' }}>
                                <div className=" col-xs-12 col-sm-6 col-md-8 software-testing-heading">
                                    <h1>Software Testing from Road Block to Opportunity</h1>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>

                                    <input type="button" className="read-more-button" value="Read More" />
                                </div>
                                {signups_sect}

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div className="software-testing-lists">
                        <div className="container">
                            <ul>
                                <li className=" border-right">
                                    <a href="#">Pumex Update Special 2018 - UTRECHT</a></li>
                                <li className="border-right"><a href="#">Software testing from road block to opportunity</a></li>
                                <li className=" border-right"><a href="#">Exploring Testing - A New Hope</a></li>
                                <li className="border-right"><a href="#">The Digital Imperative for Transforming Software</a></li>
                                <li className="user-conference"><a href="#">Pumex User Conference - Pune</a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="clearfix"></div>

                </section>

            );
        }
    }
}

export default Signup;
