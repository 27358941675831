import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import LoginHeader from './LoginHeader';

class Header extends Component {

    render() {
    

        return (
            <Switch>
            <Route  path="/"render={(props) => <LoginHeader {...props} onQnupdate={this.props.onQnupdate}  />} />
          
          </Switch>
        );
    }
}

export default Header;
