import React, { Component } from 'react';
import axios from 'axios';
import QuestionRows from './QuestionRows';
import { Link} from "react-router-dom";
import { myConfig } from '../config'



class Questions extends Component {
  constructor() {
    super();
    this.state = {
        data:
            [

            ]

    }
    axios.get(`${myConfig.apiUrl}/questions`)
        .then(res => {
            const data = res.data;
            this.setState({ data });
        })
}
  render() {
    return (
      <div className=" row" style={{margin:'10px'}}>
      <div className=" col-xs-12 col-md-12 col-lg-12">
      <div className="left-question-section">
          <h2>All Questions</h2>
          <div className="totalquestion">
              <p className="col-md-6 ">{this.state.data.length} <span className="question-section">Questions</span></p>
              <div className="col-md-6 sort-by-option">
                  <p>Sort By:<span>
                      <select name="sort" id="sort" className="input-field" autoComplete="off">
                          <option value="" >Top 20</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                      </select>
                  </span></p>

              </div>
          </div>
          {this.state.data.map((dtrow, i) => <QuestionRows key={i}
              data={dtrow} />)}


         

      </div>
  </div>
  </div>
    );
  }
}

export default Questions;
