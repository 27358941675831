import React, { Component } from 'react';
import axios from 'axios';
import UserListRow from './UserListRow';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import ReactLoading from 'react-loading';
import { myConfig } from '../config'
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);


class UsersList extends Component {
  constructor() {
    super();
    this.state = {
      data:
        [

        ],
      open: false,
      user_data: [],
      first_name: '',
      last_name: '',
      email_id: '',
      organization: '',
      mobile_no: '',
      user_id: '', password: '',
      emailValid:false,
      emailExists:false,
      formErrors: {first_name:'',last_name:'',email: '', password: ''},
    emailValid: false,
    passwordValid: false,
    fnValid:false,
    lnValid:false,
    formValid: false,
    loading:false

    }
    var baseUrl = myConfig.apiUrl
    axios.get(`${baseUrl}/users`)
      .then(res => {
        const data = res.data;
        this.setState({ data });
      })
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.editClick = this.editClick.bind(this);
    this.activateClick = this.activateClick.bind(this);
    this.deactivateClick = this.deactivateClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.addClick = this.addClick.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateField = this.validateField.bind(this);
    
    this.checkUser = this.checkUser.bind(this);
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  checkUser() {
    this.setState({loading:true})
    if(this.state.emailValid && this.state.user_id !==''){
    var email = encodeURIComponent(this.state.email);
    axios.get(`${myConfig.apiUrl}/check_email/${email}`)
        .then(res => {
            const data = res.data;
            
            if(data.status===true){
                
                this.setState({emailValid:true,loading:false},this.validateForm)
            }
            else{
                var formErrors=this.state.formErrors;
                formErrors.email="Email already exists";
                this.setState({emailValid:false,loading:false,formErrors:formErrors},this.validateForm)
            }
        })
    }
}
  editClick = (id) => {
    this.setState({
      first_name: '',
      last_name: '',
      email_id: '',
      organization: '',
      mobile_no: '',
      user_id: id
    });
    axios.get(`${myConfig.apiUrl}/user/${id}`)
      .then(res => {
        const data1 = res.data;


        this.setState({ ...data1 });

        console.log(this.state)
      })
    this.handleClickOpen()
  };
  addClick = () => {
    this.setState({
      first_name: '',
      last_name: '',
      email_id: '',
      organization: '',
      mobile_no: '',
      user_id: '',
      password: ''
    });

    this.handleClickOpen()
  };

  activateClick = (id) => {

    var payload = "user_id=" + id + "&user_status=Active";


    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    axios.put(`${myConfig.apiUrl}/user_status`, payload, config)
      .then(res => {
        const data1 = res.data;
        var baseUrl = myConfig.apiUrl
        axios.get(`${baseUrl}/users`)
          .then(res => {
            const data = res.data;
            this.setState({ data });
          })




      })

  };
  deactivateClick = (id) => {
    var payload = "user_id=" + id + "&user_status=Inactive";


    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    axios.put(`${myConfig.apiUrl}/user_status`, payload, config)
      .then(res => {
        const data1 = res.data;
        var baseUrl = myConfig.apiUrl
        axios.get(`${baseUrl}/users`)
          .then(res => {
            const data = res.data;
            this.setState({ data });
          })




      })

  };
  handleTextChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, 
    () => { this.validateField(name, value) });
  }s
  checkEmail() {
   var email_id=this.state.email_id
   axios.get(`${myConfig.apiUrl}/users`)
   .then(res => {
     const data = res.data;
     if(data.status===true)
     {}
   })
  }
  handleUpdate(event) {
    var payload = (this.state.user_id !== '') ? "user_id=" + this.state.user_id : "user_id=0";
    payload += (this.state.user_id === '') ? "&password=" + this.state.password : '';
    if (this.state.first_name !== '' && this.state.last_name !== '' && this.state.email_id !== '') {
      payload += (this.state.first_name) ? "&first_name=" + this.state.first_name : '';
      payload += (this.state.last_name) ? "&last_name=" + this.state.last_name : '';

      payload += (this.state.email_id) ? "&email_id=" + this.state.email_id : '';
      payload += (this.state.organization) ? "&organization=" + this.state.organization : '';
      payload += (this.state.mobile_no) ? "&mobile_no=" + this.state.mobile_no : '';

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios.post(`${myConfig.apiUrl}/myaccount`, payload, config)
        .then(res => {
          const data1 = res.data;
          var baseUrl = myConfig.apiUrl
          if (res.data.status === true) {
            alert(res.data.message)
            axios.get(`${baseUrl}/users`)
              .then(res => {
                const data = res.data;
                this.setState({ data });
                this.setState({
                  open: false,
                  first_name: '',
                  last_name: '',
                  email_id: '',
                  organization: '',
                  mobile_no: '',
                  user_id: ''
                });
              })
          }



        })
    }
    else {
      alert("Enter all required fields !!")
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let fnValid = this.state.fnValid;
    let lnValid = this.state.lnValid;
    switch(fieldName) {
      case 'first_name':

        fnValid = (value!=='')? true:false;
        fieldValidationErrors.first_name = fnValid ? '' : 'First Name id is invalid';
        break;
        case 'last_name':

        lnValid = (value!=='')? true:false;
        fieldValidationErrors.last_name = lnValid ? '' : 'Last Name id is invalid';
        break;
      case 'email_id':

        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email id is invalid';
        break;
      case 'password':

        passwordValid = (this.state.user_id==='' && value!=='') ? true :false;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
   if(this.state.user_id===''){
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid,
                    fnValid:fnValid,
                    lnValid:lnValid
                  }, this.validateForm);
                }
                else{
                  this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    
                    fnValid:fnValid,
                    lnValid:lnValid
                  }, this.validateForm);
                }
  }
  
  validateForm() {
    if(this.state.user_id===''){
    this.setState({formValid: this.state.emailValid && this.state.passwordValid && this.state.fnValid && this.state.lnValid});
    }
    else{
      this.setState({formValid: this.state.emailValid &&  this.state.fnValid && this.state.lnValid});
    }
  }
  render() {
    return (
      <div id="usermanagement">
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth={'md'}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            <b>{(this.state.user_id === '') ? "Add User" : "Edit User details"}</b>
          </DialogTitle>
          <DialogContent>
            <div className="md-dialog-content">
              <div className="row" >

                <div className="col-md-12">
                  <label htmlFor="firstname">First Name <span className="mandatory">
                    *</span></label>
                  <input type="text" name="first_name" id="first_name" onChange={this.handleTextChange} value={(this.state.first_name !== null) ? this.state.first_name : ''} className="form-control" autoComplete="off"
                    style={{ width: '100%' }} />
                  {(!this.state.fnValid ) ? (
                    <span

                      className="field_error" style={{ color: 'red' }} >This field is required</span>
                  ) :
                    (null)
                  }
                </div>
              </div>

              <div className="row" >

                <div className="col-md-12">
                  <label htmlFor="last_name">Last Name <span className="mandatory">
                    *</span></label>
                  <input type="text" name="last_name" id="last_name" onChange={this.handleTextChange} value={(this.state.last_name !== null) ? this.state.last_name : ''} className="form-control" autoComplete="off"
                    style={{ width: '100%' }} />
                  {(!this.state.lnValid) ? (
                    <span

                      className="field_error" style={{ color: 'red' }} >This field is required</span>
                  ) :
                    (null)
                  }
                </div>
              </div>
              <div className="row" >

                <div className="col-md-12">
                  <label htmlFor="email_id">Email-ID <span className="mandatory">
                    *</span></label>
                  <input type="text" name="email_id" id="email_id" onChange={this.handleTextChange} onBlur={this.checkUser} value={(this.state.email_id !== null) ? this.state.email_id : ''} className="form-control" autoComplete="off"
                    style={{ width: '100%' }} />
                  {(!this.state.emailValid ) ? (
                    <span

                      className="field_error" style={{ color: 'red' }} >This field is required</span>
                  ) :
                    (null)
                  }
                </div>
              </div>
              {(this.state.user_id === '') &&
                (<div className="row" >

                  <div className="col-md-12">
                    <label htmlFor="password">Password <span className="mandatory">
                      *</span></label>
                    <input type="password" name="password" id="password" onChange={this.handleTextChange} className="form-control" autoComplete="off"
                      style={{ width: '100%' }} />
                    {(!this.state.passwordValid ) ? (
                      <span

                        className="field_error" style={{ color: 'red' }} >This field is required</span>
                    ) :
                      (null)
                    }
                  </div>
                </div>)
              }
              <div className="row" >

                <div className="col-md-12">
                  <label htmlFor="organization">Organization <span className="mandatory">
                    *</span></label>
                  <input type="text" name="organization" id="organization" onChange={this.handleTextChange} value={(this.state.organization !== null) ? this.state.organization : ''} className="form-control" autoComplete="off"
                    style={{ width: '100%' }} />

                </div>
              </div>

              <div className="row" >

                <div className="col-md-12">
                  <label htmlFor="mobile_no">Mobile No. <span className="mandatory">
                    *</span></label>
                  <input type="text" name="mobile_no" id="mobile_no" onChange={this.handleTextChange} value={(this.state.mobile_no !== null) ? this.state.mobile_no : ''} className="form-control" autoComplete="off"
                    style={{ width: '100%' }} />

                </div>
              </div>





              <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>

                <div className="col-md-12">

                  <button className="signupbtn text-center" disabled={ !this.state.formValid } onClick={this.handleUpdate} style={{ width: '150px' }} >
                    Submit</button>


                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
        <section>
          <div className="container" style={{ maxWidth: '1613px', margin: '5px auto', width: '85%', marginLeft: '10%', marginBottom: '50px' }}>
            <div className="ranked-section">
              <div className="clearfix" />
              <div className="question-display-section">
                <div id="listusers">
                  <div className="profile-setting-section">
                    <h2 style={{ marginLeft: '1%' }}>User Management</h2>
                    <div className="settings-image-section" >
                      <button className="btn btn-primary" onClick={this.addClick}  >

                        Add User</button>
                    </div>
                  </div>
                  {/* Default panel contents */}
                  <section>
                    <div className="table-responsive" style={{ marginLeft: '1%', marginRight: '1%' }}>
                      <table datatable="ng" className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th className="align_center setcolor">#</th>
                            <th className="align_center setcolor">Name</th>
                            <th className="align_center setcolor">Email</th>
                            <th className="align_center setcolor">User Role</th>
                            <th className="align_center setcolor">Organization</th>
                            <th className="align_center setcolor">Member Since</th>
                            <th className="align_center setcolor">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((dtrow, i) => <UserListRow editfn={this.editClick} activateFn={this.activateClick} deactivateFn={this.deactivateClick} key={i}
                            data={dtrow} index={i} />)}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>);
  }
}

export default UsersList;
