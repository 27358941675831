import React, { Component } from 'react';
import axios from 'axios';
import { Link} from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import { myConfig } from '../config'

class QuestionAns extends Component {

	  constructor(props) {
		    super();
		    this.state = {
		        data:
		            [

		            ],
		            ans: [],
		            comments:[],
		            ans_txt:null,
		            qn_id:props.match.params.qn_id,
		            user_id: localStorage.getItem("user_id"),
		            user_data:[]
		           

		    }
		    this.handleSubmit = this.handleSubmit.bind(this);
		    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
		    let qnId = props.match.params.qn_id;
			  // const { id } = this.props.match.params.qn_id;
		    // var url = `http://localhost:4000/question/${qnId}`;
		    var url = `${myConfig.apiUrl}/question/${qnId}`;
		    axios.get(url)
		        .then(res => {
		            const data = res.data;
		            
		            // var url1 = `http://localhost:4000/answerCount/${qnId}`;
		            var url1 = `${myConfig.apiUrl}/answerCount/${qnId}`;
		            axios.get(url1)
			        .then(res => {
			            const data1 = res.data;
			            data.answer_count = data1.answercount;
			            this.setState({ data });
			        })
		            
		            
		            
		            this.setState({ data });
		            
		            
		        })
		        
		        // var url2 = `http://localhost:4000/answers/${qnId}`;
		        var url2 = `${myConfig.apiUrl}/answers/${qnId}`;
		        axios.get(url2)
			        .then(res => {
			            const data = res.data;
			            this.setState({ ans: data });
			        })
		    
                    var url3 = `${myConfig.apiUrl}/user/${localStorage.getItem('user_id')}`;
                    axios.get(url3)
                        .then(res => {
                            const data = res.data;
                            this.setState({ user_data: data });
                        })	        
		}
	  createMarkup(data) {
	        return {__html: data};
	      }

	  
	  handleSubmit(event) {
		  
		  
		  if(event.target[0].value)
			  {
			// var apiBaseUrl = "http://localhost:4000/";
			  var apiBaseUrl = myConfig.apiUrl+"/";
		        const data = new FormData()
		        if(this.state.selectedFile)
		        data.append('attachment', this.state.selectedFile, this.state.selectedFile.name)
		        data.append('question_id',this.state.qn_id)
		        data.append('answer_txt', event.target[0].value)
		        data.append('user_id', localStorage.getItem("user_id"))
		        axios
		            .post(apiBaseUrl + "answers", data, {
		                onUploadProgress: ProgressEvent => {
		                },
		            })
		            .then(res => {

		            	//var url3 = `http://localhost:4000/answers/${this.state.qn_id}`;
		            	var url3 = `${myConfig.apiUrl}/answers/${this.state.qn_id}`;
		            	 axios.get(url3)
					        .then(res => {
					            const data = res.data;
					            var message = this.refs.message;
					            this.state.data.answer_count = this.state.data.answer_count+1;
					            this.setState({ ans: data });
					            message.value = "";
					        })
		            })

			  }
		 
	    
	            event.preventDefault();
	    
	  }
	 
	        onUpdateQn (likeOrUnlike)
	        {
	        	
	        	var userId = localStorage.getItem("user_id");
	        	if(userId)
	        		{
	        		var qn_id = this.state.qn_id;
		        	if(likeOrUnlike == 1)
		        		{
		        		//var url = `http://localhost:4000/like/question/${userId}/${qn_id}`;
		        		var url = `${myConfig.apiUrl}/like/question/${userId}/${qn_id}`;
		        		}
		        	
		        	else
		        		{
		        		//var url = `http://localhost:4000/unlike/question/${userId}/${qn_id}`;
		        		var url = `${myConfig.apiUrl}/unlike/question/${userId}/${qn_id}`;
		        		}
		        			
		        	 axios.get(url)
				        .then(res => {
				            const data = res.data;
				            const obj=this.state.data;
				            if(data.Total>0)
					        	obj.likes=data.Total;
					            else
					            	obj.likes=0;
				        	this.setState(obj);
				            
				            
				        })
	        		}
	        	else
	        		{
	        		alert("You need to login to give your vote!");
	        		}
	        	
	        	
	        	
	        }
	        
	        onUpdateItem (index,likeOrUnlike)
	        {
	        	 
	        	if(this.state.user_id)
	        		{
	        		
	        		const obj=this.state.ans[index];
		        	var userId = localStorage.getItem("user_id");
		        	var ans_id = obj.ans_id;
		        	if(likeOrUnlike == 1)
		        		{
		        		//var url = `http://localhost:4000/like/answer/${userId}/${ans_id}`;
		        		var url = `${myConfig.apiUrl}/like/answer/${userId}/${ans_id}`;
		        		}
		        	
		        	else
		        		{
		        		//var url = `http://localhost:4000/unlike/answer/${userId}/${ans_id}`;
		        		var url = `${myConfig.apiUrl}/unlike/answer/${userId}/${ans_id}`;
		        		}
		        			
		        	 axios.get(url)
				        .then(res => {
				            const data = res.data;
				            const obj=this.state.ans[index];
				            if(data.Total>0)
				        	obj.likes=data.Total;
				            else
				            	obj.likes=0;	
				        	this.setState(obj);
				            
				            
				        })
	        		}
	        	else
	        		{
	        		
	        		alert("You need to login to give your vote!");
	        		
	        		}
	        	
	        	
	        }
	        
	        showComments (index)
	        {
	        	 const obj=this.state.ans[index];
	        	 
	        	 if(obj.comments)
	        		 {
	        		 
	        		 obj.comments = [];
	        		 this.setState(obj)
	        		 }
	        	 else
	        		 {
	        			 var ans_id = obj.ans_id;
	    	        	 var url = `${myConfig.apiUrl}/comments/${ans_id}`;	
	    	        	// var url = `http://localhost:4000/comments/${ans_id}`;	
	    	        	 axios.get(url)
	    			        .then(res => {
	    			            const data = res.data;
	    			            obj.comments = data;
	    			            obj.addCommentVal ='';
	    			            this.setState(obj)
	    			            
	    			        })
	        		 }
	        	
	        }
	        
	        
	        addComment (index)
	        {
	        	 const obj=this.state.ans[index];
	        	 var ans_id = obj.ans_id;
	        	 obj.showComment = true;
	        	 this.setState(obj)
	        	
	        }
	        handleCommentSubmit(event) {
	        	
	        	if(event.target[0].value)
	        		{
	        		var indexOfList = event.target[2].value;
		        	var apiBaseUrl = myConfig.apiUrl+"/"
		        	//var apiBaseUrl = "http://localhost:4000/";
			        axios
			            .post(apiBaseUrl + "comment",
			            		{
			            	ans_id: event.target[1].value,
			            	comment_text: event.target[0].value,
			            	commented_by: localStorage.getItem("user_id")
			              }		
			            )
			            .then(res => {
			            	const obj=this.state.ans[indexOfList];
				        	 var ans_id = obj.ans_id;
				        	 obj.addCommentVal ='';
				        	 obj.comments_count =obj.comments_count+1;
				        	 obj.showComment = false;
				        	 this.setState(obj)
			            })
		        	
		        	
		        	
		        	
	        		}
	        	
	        	 event.preventDefault();
	          }
	        createMarkup(data) {
	            return {__html: data};
	          }
	        
	        
  render() {
	 
	   this.items = this.state.ans.map((item, index) =>
	   <li>
       <div className="col-xs-12 col-sm-2 col-md-1">
           <div className="image-section">
           <a href={`/user/${item.answered_by}`}>
           <img src="../images/defpic.png" />
           </a>
               
               <a onClick={e => this.onUpdateItem(index,1)} className="up_arrow"></a>
               <h6>{item.likes}</h6>
               <a onClick={e => this.onUpdateItem(index,0)} className="down_arrow"></a>

           </div>
       </div>
       <div className="col-xs-12 col-sm-10 col-md-11">
           <div className="comment-content">
               <div className="name-date">
                   <div className="name-section">
                       <h5><a href="">{item.fullname}</a> <span>Answered</span></h5>

                   </div>

                   <div className="date-section">
                       <h5><Moment format="ddd D h:mm A">
                       {item.date_created}
                       </Moment></h5>
                   </div>

               </div>
               <div className="clear"></div>
               <div className="comment-description" >
                  {item.ans_txt}

               </div>

               <div className="relytocomment-section">
                   <h6><a onClick={e => this.showComments(index)} >{item.comments_count} Comments<span><img   src="../images/comment-up-arrow.png" /></span></a></h6>
                   <div className="reply-icon">
                       
                   
                   {this.state.user_id ? (
                		   <a onClick={e => this.addComment(index)} className="comment-btn" id="scroll">Comment</a>
                       ) : (null)
                       }
                      
                       
                   </div>


                   
                   </div>
                   
                   
                   {item.showComment  === true ? (
                		   <section id="custom-answers-section">
                           <div className="container">
                               <div className="row">
                               <div className=" col-xs-6 col-md-6 col-lg-6">
                               <div className="answer-message">
                               <div className=" col-xs-12 col-sm-10 col-md-11 right-content">
                               <form data-key={index} onSubmit={this.handleCommentSubmit} name="form"  className="row">
                               <div className="form-group">
                               <textarea className="form-control summernote" rows="6" id="comment" name="comment" value={item.addCommentVal}  placeholder="Comment" ></textarea>
                               </div>
                               <input type="hidden" id="ans_id" value={item.ans_id} />
                               <input type="hidden" id="getIndex" value={index} />
                               <button type="sumbit" className="btn submit_btn">Submit</button>
                               </form>
                               </div>
                               </div>
                               </div> 
                                  
                               </div>
                           </div>
                       </section>	   
                   ) : (null)
                   }
                   
                   
                   
                   
                   <ul className= {`parent-comment${item.ans_id}`}>
                   {item.comments ? (
                		   item.comments.map((comment, index) =>
                		   <li>
                           <div className="col-xs-12 col-sm-2 col-md-1">
                           <div className="image-section">
                           <img src="../images/P-img4.jpg"/>
                           </div>
                           </div>
                           <div className="col-xs-12 col-sm-10 col-md-11">
                           <div className="comment-content">
                           <div className="name-date">
                           <div className="name-section">
                           <h5><a href="">{comment.fullname}</a> <span>Commented</span></h5>
                           </div>
                           <div className="date-section">
                           <h5><Moment format="ddd D h:mm A">
                           {comment.date_created}
                           </Moment></h5>
                           </div>
                           </div>
                           <div className="clear">
                           </div>
                           <div className="comment-description">{comment.comment_text}</div>
                           <div className="clear"></div>
                           </div>
                           </div>
                           <div className="clear"></div>
                           </li>
                      )    
                   ) : (null)
                   }
                   </ul>
                   
                   
                   
                   
                   
                   
                   
                   
                   
             
               <div className="clear"></div>
           </div>

       </div>
       <div className="clear"></div>

   </li>
   );
    return (
      <div>
      <section id="question-answers-section">
      <div className="container">
          <div className="row">
              <div className=" col-xs-12 col-md-12 col-lg-12">
                  <div className="question-answers-listing">
                      <h2>Question & Answers  </h2>

                      <div className="question-display-section ">
                          <div className="left-question-img">
                              <img style={{width:'130px'}} src={this.state.data.profile_photo!=null ? myConfig.apiUrl+"/get_file/"+this.state.data.profile_photo :"../images/defpic.png"} />
                          </div>
                          <div className="question_answer_vote_section ">
                           <div className="arrows">
                                      <a onClick={e => this.onUpdateQn(1)} className="up_arrow"></a>
                                      <h6>{this.state.data.total_votes}</h6>
                                      <a onClick={e => this.onUpdateQn(0)} className="down_arrow"></a>
                                  </div>
                          </div>
                          <div className="right-question-content " style={{float:'right'}}>
                              <div className="name-date">
                                  <div className="name-section">
                                      <h5>{this.state.data.fullname}</h5>
                                  </div>
                                  <div className="date-section">
                                      <h5> <Moment format="ddd D h:mm A">
                                      {this.state.data.date_created}
                                      </Moment></h5>
                                  </div>

                              </div>
                              <div className="clear"></div>
                              
                                  <div className="question-details">
                                      <p><Link to={'/questions/'+this.state.data.qn_id} dangerouslySetInnerHTML={this.createMarkup(this.state.data.qn_txt)}></Link> </p>
                                  
                                 
                                  <div className="clear"></div>


                              </div>

                          </div>

                      </div>
                  </div>

                  <div className="clear"></div>
              </div>
          </div>

      </div>

  </section>
  <section id="answer-content">
      <div className="container">
          <div className="row">
              <div className=" col-xs-12 col-md-12 col-lg-12">
                  <div className="answer-section">
                      <div className=" col-xs-12 col-sm-6 col-md-6 answers-number">
                      {this.state.user_id ? (
                    		  <a href="#">REPLY</a>
                          ) : (null)
                          }
                          <p>{this.state.data.answer_count} Answers</p>
                      </div>
                      <div className=" col-xs-12 col-sm-6 col-md-6 sort-by-option">
                          <p>Sort By:<span>
                           
                      <select name="sort" id="sort" className="input-field" autoComplete="off">
                      <option value="" > Votes</option>
                       </select>
                              
                                  </span></p>

                      </div>
                  </div>
              </div>
          </div>

      </div>

  </section>
  <section id="answers-fullsection">
      <div className="container">
          <div className="row">
          
          {this.items.length>0 ? (
        		  <div className=" col-xs-12 col-md-12 col-lg-12">
                  <div className="answer-listing-section">
                      <ul className="parent-comment">
                      {this.items}
                      </ul>
                  </div>
              </div>
              ) : (null)
              }
              
          </div>
      </div>
  </section>

  {this.state.user_id ? (
		  <section ref="test"  id="custom-answers-section">
          <div className="container">
              <div className="row">
                  <div className=" col-xs-12 col-md-12 col-lg-12">
                      <div className="answer-message">
                          <h2>Answers</h2>

                          <div className=" col-xs-12 col-sm-2 col-md-1 left-img">
                              <img style={{width:'80px'}} src={this.state.user_data.profile_photo!=null ? myConfig.apiUrl+"/get_file/"+this.state.user_data.profile_photo :"../images/defpic.png"} />
                          </div>
                          <div className=" col-xs-12 col-sm-10 col-md-11 right-content">

                              <form onSubmit={this.handleSubmit} id="answer-form" name="form"  className="row">

                                  <div className="form-group">

                                      <textarea ref="message" className="form-control summernote" rows="6" id="message" name="message" value={this.state.answertxt} placeholder="Message" autoComplete="off"></textarea>
                                     
                                      
                                      
                                      
                                      

                                      <div id="err1" className="err"></div>
                                  </div>
                                  <button  type="sumbit" className="btn submit_btn">Submit</button>
                                  <div id="result"></div>
                              </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>  
  ) : (null)
  }

          
  </div>
    );
  }
}

export default QuestionAns;
