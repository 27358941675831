import React, { Component } from 'react';
import axios from 'axios';

import 'font-awesome/css/font-awesome.min.css';
import UserqnRows from './UserqnRows';
import UseransRows from './UseransRows';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import ReactLoading from 'react-loading';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { myConfig } from '../config'
const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);
class MyProfile extends Component {
    constructor() {
        super();
        this.state = {
            data:
                [

                ],
            ctab: 1,
            editField: false,
            editFile: false,
            FirstName: '',
            LastName: '',
            OrgName: '',
            MobileNo: '',
            selectedFile: null, loaded: 0,
            qnData: [],
            ansData: [],
            crop: {
                x: 10,
                y: 10,
                aspect: 1,
                width: 50,
                maxWidth: 50
            },
            open: false,
            selectedFile: null,
            fileContent: null
        }
        var user_id = localStorage.getItem('user_id');
        axios.get(`${myConfig.apiUrl}/user/${user_id}`)
            .then(res => {
                const data = res.data;

                this.setState({ data });
                this.setState({ FirstName: data.first_name, LastName: data.last_name, OrgName: data.organization, MobileNo: data.mobile_no });

            })
        axios.get(`${myConfig.apiUrl}/user_questions/${user_id}`)
            .then(res => {
                const data1 = res.data;

                this.setState({ qnData: data1 });


            })
        axios.get(`${myConfig.apiUrl}/user_answers/${user_id}`)
            .then(res => {
                const data2 = res.data;

                this.setState({ ansData: data2 });


            })
        this.changeTab = this.changeTab.bind(this);
        this.editClick = this.editClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.updatePhoto = this.updatePhoto.bind(this);


    }
    handleClose = () => {
        this.setState({ open: false });
    };
    onSelectFile = (e) => {
        console.log("File size:"+e.target.files[0].size);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
        this.makeClientCrop(this.state.crop, pixelCrop);
    }

    onCropComplete = (crop, pixelCrop) => {
        console.log('onCropComplete', { crop, pixelCrop });
        this.makeClientCrop(crop, pixelCrop);
    }

    onCropChange = (crop) => {
        // console.log('onCropChange', crop);
        this.setState({ crop });
    }

    getCroppedImg(image, pixelCrop, fileName) {
        //console.log('getCroppedImg', { image, pixelCrop, fileName });
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );
        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({ fileContent: base64Image })
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                blob.name = fileName; // eslint-disable-line no-param-reassign
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg',
            ).then(croppedImageUrl => this.setState({ croppedImageUrl }));
        }
    }

    renderSelectionAddon = () => (
        <button
            type="button"
            style={{
                position: 'absolute',
                bottom: -25,
                right: 0,
            }}
            onClick={() => window.alert('You click addon!')}
        >
            custom addon
        </button>
    );

    handleselectedFile = event => {
        console.log("dgdf"+event.target.files[0].length);
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    updateProfile() {
        var apiBaseUrl = myConfig.apiUrl;
        var self = this;

        var payload = "email=" + this.state.email + "&password=" + this.state.password + "&fullname=" + this.state.fullname;

        const data = new FormData()
        if (this.state.selectedFile)
            data.append('profile_photo', this.state.selectedFile, this.state.selectedFile.name)
        data.append('fullname', this.state.FirstName + ' ' + this.state.LastName)
        data.append('first_name', this.state.FirstName)
        data.append('last_name', this.state.LastName)

        data.append('organization', this.state.OrgName)
        //data.append('address', this.state.message)
        //data.append('city', this.state.message)
        //data.append('state', this.state.message)
        //data.append('country', this.state.message)
        //data.append('zipcode', this.state.message)
        data.append('mobile_no', this.state.MobileNo)


        data.append('user_id', localStorage.getItem("user_id"))
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        var self = this;
        axios.post(apiBaseUrl + '/myaccount', data, config)
            .then(function (response) {


                if (response.data.status == true) {
                    alert("Profile updated successfully");

                    var user_id = localStorage.getItem('user_id');
                    axios.get(`${myConfig.apiUrl}/user/${user_id}`)
                        .then(res => {
                            const data = res.data;

                            self.setState({ data });
                            self.setState({ FirstName: data.first_name, LastName: data.last_name, OrgName: data.organization, MobileNo: data.mobile_no });
                            self.setState({ editField: false })
                        })

                    //self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                }
                else {

                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    changeTab(value) {

        this.setState({ ctab: value })
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };
    editClick(value) {
        if (value === 2)
            this.setState({ editField: !this.state.editField })

    }
    convertDate(date) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var curday = new Date(date);
        return curday.toLocaleDateString("en-US", options);
    }
    updatePhoto() {
       // console.log('in');

        var apiBaseUrl = myConfig.apiUrl;
        var self = this;

        var payload = "user_id=" + localStorage.getItem("user_id") + "&fileName=" + encodeURIComponent(this.state.fileContent);

        const data = new FormData()
        if (this.state.fileContent) {
            // data.append('fileName', this.state.fileContent)





            data.append('user_id', localStorage.getItem("user_id"))
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            var self = this;
            axios.post(apiBaseUrl + '/update_photo', payload, config)
                .then(function (response) {


                    if (response.data.status == true) {
                        alert("Photo updated successfully");

                        var user_id = localStorage.getItem('user_id');
                        axios.get(`${myConfig.apiUrl}/user/${user_id}`)
                            .then(res => {
                                const data = res.data;

                                self.setState({ data });
                                self.setState({ FirstName: data.first_name, LastName: data.last_name, OrgName: data.organization, MobileNo: data.mobile_no });
                                self.setState({ editField: false })
                                self.setState({ open: false })
                            })

                        //self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                    }
                    else {

                        alert(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }
    render() {
       // console.log(this.state.fileContent)
        const { croppedImageUrl } = this.state;
        var tabData = null
        if (this.state.ctab === 1) {
            tabData = <div>
                {this.state.qnData.length === 0 ?
                    (
                        <div >
                            <div className="answer-listing-section question_inner_container">
                                <ul className="parent-comment myUl">
                                    <li className="myUl">

                                        <div className="col-xs-12 col-sm-10 col-md-11">
                                            <div className="comment-content">


                                                <div className="comment-description font_bold">
                                                    <p className="ng-binding" style={{ textAlign: 'center' }}>Not
                                        yet asked any Questions</p>
                                                </div>



                                            </div>

                                        </div>
                                        <div className="clear"></div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) :
                    (
                        <div>
                            {this.state.qnData.map((dtrow, i) => <UserqnRows key={i}
                                data={dtrow} index={i} />)}
                        </div>
                    )
                }
            </div>
        }
        else if (this.state.ctab === 2) {
            tabData = <div>
                {this.state.ansData.length === 0 ?
                    (
                        <div >
                            <div className="answer-listing-section question_inner_container">
                                <ul className="parent-comment myUl">
                                    <li className="myUl">
                                        <div className="col-xs-12 col-sm-10 col-md-11">
                                            <div className="comment-content">
                                                <div className="comment-description font_bold">
                                                    <p className="ng-binding" style={{ textAlign: 'center' }}>Not
															yet Answered any Questions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) :
                    (
                        <div>
                            {this.state.ansData.map((dtrow, i) => <UseransRows key={i}
                                data={dtrow} index={i} />)}
                        </div>
                    )}
            </div>
        }
        else if (this.state.ctab === 3) {
            tabData = <div>
                <div className="answer-listing-section question_inner_container">
                    <div style={{ marginLeft: '10%' }}>
                        <b>Badges –</b> <br /> <br />

                        <div >
                            <p className="ng-binding" style={{ textAlign: 'center' }}>No Badges</p>
                        </div>





                        <a ng-repeat="Ubadge in ctrl.badgesList" href="#!/questionanswers">

                            <img
                                src="" />
                        </a>



                    </div>
                    <br /> <br />
                    <div className="clear"></div>
                </div>





            </div>

        }
        return (

            <div>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        <b>Crop Image</b>
                    </DialogTitle>
                    <DialogContent>
                        <div className="md-dialog-content">



                            <div className="row" style={{ minHeight: '250px' }}>
                                <div className="col-md-6" style={{ padding: '1%' }}>
                                    <div>
                                        <label className="browseBtn"> <label> <input
                                            type="file" id="fileInput" accept=".jpg,.jpeg,.gif,.png" onChange={this.onSelectFile}
                                             />
                                        </label></label>
                                    </div>
                                    <br />
                                    <div className="cropArea">
                                        {this.state.src && (
                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}

                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6" style={{ padding: '1%' }}>

                                    <div style={{ marginTop: '3%' }}><b>Cropped Image:</b></div>
                                    <div style={{ marginTop: '7%', maxWidth: '90%' }}>
                                        {this.state.fileContent && <img alt="Crop" src={this.state.fileContent} style={{ width: '100%' }} />}

                                    </div>
                                </div>
                            </div>



                            <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <button type="button" className="signupbtn" style={{ width: '150px' }}
                                        onClick={this.updatePhoto}>Save</button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
                <section id="profile-section">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12 col-md-12 col-lg-12">
                                <div className="profile-listing">
                                    <div className="profile-setting-section">
                                        <h2>Profile</h2>
                                        <div
                                            className="settings-image-section">
                                            <a onClick={() => this.editClick(2)}> <span
                                                className="glyphicon glyphicon-pencil"></span>
                                            </a>

                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="profile-custom-content">

                                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-7">
                                            <div className="profile-fullcontent">
                                                <div className="profile-image-section">
                                                    <img src={this.state.data.profile_photo != null ? myConfig.apiUrl + "/get_file/" + this.state.data.profile_photo : "../images/defpic.png"} />
                                                </div>
                                                {!this.state.editField ? (
                                                    <div>
                                                        <div
                                                            className="edit">
                                                            <a onClick={() => this.handleClickOpen()}><i
                                                                className="fa fa-pencil fa-lg"></i></a>
                                                        </div>
                                                        <div className="form-group">


                                                        </div>
                                                    </div>
                                                ) : (null)
                                                }
                                                <div className="profile-details">
                                                    <h3>{this.state.data.fullname}</h3>
                                                    {this.state.editField ? (

                                                        <div>
                                                            <input type="text"
                                                                placeholder="FirstName"
                                                                style={{ width: '100%' }}
                                                                className="form-control"
                                                                id="FirstName" onChange={this.handleInputChange} name="FirstName" value={this.state.FirstName} maxLength="100" tabIndex="1" />
                                                            {(this.state.FirstName === '') &&
                                                                <span className="field_error" style={{color: 'red'}}>This field is required</span>
                                                            }
                                                            <input type="text"

                                                                style={{ width: '100%', marginTop: '10px' }} className="form-control"
                                                                placeholder="LastName"
                                                                id="LastName" onChange={this.handleInputChange} name="LastName" value={this.state.LastName} maxLength="100" tabIndex="1" />
                                                            {(this.state.LastName === '') &&
                                                                <span className="field_error" style={{color: 'red'}}>This field is required</span>
                                                            }
                                                        </div>
                                                    ) : (
                                                            null
                                                        )}

                                                    <p className="duration"><i>Member since {this.convertDate(this.state.data.date_added)}</i></p>
                                                    <div >
                                                        <h6
                                                            style={{ color: '#000', fontSize: '14px', fontFamily: 'Dupla' }}>Organization</h6>
                                                        {!this.state.editField ? (
                                                            <h6 className="graycolor-address"
                                                                style={{ fontSize: '14px', fontFamily: 'Dupla' }}>{this.state.data.organization}</h6>
                                                        ) : (
                                                                null
                                                            )}
                                                        {this.state.editField ? (
                                                            <div>
                                                                <input type="text"

                                                                    style={{ width: '100%' }} className="form-control"
                                                                    id="OrgName" name="OrgName" onChange={this.handleInputChange}
                                                                    maxLength="100" placeholder="Organization Name" tabIndex="1" value={this.state.OrgName} />
                                                                <input ng-show="ctrl.editUser==2" disabled={(this.state.FirstName === '' || this.state.LastName === '')} onClick={this.updateProfile}
                                                                    className="read-more-button" value="Update" type="button" />
                                                            </div>
                                                        ) : (
                                                                null
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                                            <div className="col-xs-12 col-sm-12 col-md-12 address-details">

                                                <ul>
                                                    <li>
                                                        <h6>Email Id</h6>
                                                        <h6 className="graycolor-address">{this.state.data.email_id}</h6>
                                                    </li>
                                                    <li>
                                                        <h6>Mobile No</h6>
                                                        {!this.state.editField ? (
                                                            <h6 className="graycolor-address">{this.state.data.mobile_no}</h6>
                                                        ) : (null)
                                                        }
                                                        {this.state.editField ? (
                                                            <input type="text"
                                                                value={this.state.MobileNo}
                                                                style={{ width: '90%' }} className="form-control"
                                                                onChange={this.handleInputChange} id="MobileNo" name="MobileNo"
                                                                placeholder="MobileNo" maxLength="100" tabIndex="1" />
                                                        ) : (
                                                                null
                                                            )}
                                                    </li>

                                                </ul>



                                            </div>




                                        </div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section id="profile-menu">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12 col-md-12 col-lg-12">
                                <div id="tab">
                                    <ul className="profile-menu-content resp-tabs-list">

                                        <li onClick={() => this.changeTab(1)} id="tab1" style={{ cursor: 'pointer' }} className={(this.state.ctab == 1) ? 'profile-detail-menu resp-tab-item resp-tab-active' : 'profile-detail-menu resp-tab-item'}>Questions
                                   </li>

                                        <li id="tab2" onClick={() => this.changeTab(2)} style={{ cursor: 'pointer' }} className={(this.state.ctab == 2) ? 'profile-detail-menu resp-tab-item resp-tab-active' : 'profile-detail-menu resp-tab-item'}>Reply
                                   </li>

                                    </ul>
                                    <div className="resp-tabs-container">

                                        {tabData}






                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}

export default MyProfile;
