import React, { Component } from 'react';
import { Link} from "react-router-dom";
import { myConfig } from '../config'
class ResultRow extends Component {
    constructor(props) {
        super(props);
      
        }
    createMarkup(data) {
        return {__html: data};
      }
      dateDiff(date_val){
        var date_now=new Date()
        var date_value=new Date(date_val)
      var d = Math.abs(date_now-date_value) / 1000;                           // delta
      var r = {};                                                                // result
      var s = {                                                                  // structure
          year: 31536000,
          month: 2592000,
          //week: 604800, // uncomment row to ignore
          day: 86400,   // feel free to add your own row
          hour: 3600,
          minute: 60,
          second: 1
      };
      
      Object.keys(s).forEach(function(key){
          r[key] = Math.floor(d / s[key]);
          d -= r[key] * s[key];
      });
      
      // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
      return r;
    }
    render() {
        var datediff=this.dateDiff(this.props.data.date_created);
        var minIndex=(this.props.currentPage-1)*this.props.itemsperPage;
        var maxIndex=(this.props.currentPage)*this.props.itemsperPage;
        console.log(minIndex+","+maxIndex);
        if(this.props.index>=minIndex && this.props.index<maxIndex) {
        return (
            <div>
            <div className="question-display-section">
                <div className="left-question-img">

                       {localStorage.getItem('isLoggedin') ? (
                        <Link to={(localStorage.getItem('user_id') == this.props.data.posted_by) ? '/my-profile' : '/user/' + this.props.data.posted_by} ><img src={this.props.data.profile_photo != null ? myConfig.apiUrl + "/get_file/" + this.props.data.profile_photo : "../images/defpic.png"} alt="" /></Link>
                    ) :
                        (<img src={this.props.data.profile_photo != null ? myConfig.apiUrl + "/get_file/" + this.props.data.profile_photo : "../images/defpic.png"} alt="" />)}
                    </div>
                <div className="right-question-content">
                    <p><Link to={'/questions/'+this.props.data.qn_id} dangerouslySetInnerHTML={this.createMarkup(this.props.data.qn_title)}></Link></p>
                    <div className=" col-xs-12 col-sm-5 answers-number">
                        <p>{(this.props.data.answers_count>0) ?'Answered' : ''}</p>


                    </div>
                    <div className=" col-xs-12 col-sm-5 col-sm-offset-left-2 single-question">
                        <div className="custom-vote-section">
                            <div className="col-xs-4 answer-number-display">
                                <p><span>{this.props.data.answers_count}</span> Answers</p>

                            </div>

                            <div className=" col-xs-4 vote_to_question">
                                <div className="arrows">
                                    <a href="javascript:void(0)" onClick={() => this.props.onUpVote(this.props.data.qn_id)} className="up_vote_arrow"></a>
                                    <a href="javascript:void(0)" onClick={() => this.props.onDownVote(this.props.data.qn_id)} className="down_vote_arrow"></a>
                                </div>
                                <h6>{this.props.data.likes}</h6>
                            </div>
                            <div className=" col-xs-4 viewers_count">
                                <h6><span ><img src="../images/eye1.png" /></span>{this.props.data.total_views}</h6>
                            </div>
                        </div>
                    </div>

                    <div className="clear"></div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
        
        );
         }
         else{
             return null;
         }
    }
}

export default ResultRow;
