import React, { Component } from 'react';


class QuestionDetail extends Component {
           
  render() {
    return (
    	      <div>
    	      <section id="question-answers-section">
    	      <div className="container">
    	          <div className="row">
    	              <div className=" col-xs-12 col-md-12 col-lg-12">
    	                  <div className="question-answers-listing">
    	                      <h2>Question & Answers</h2>

    	                      <div className="question-display-section ">
    	                          <div className="left-question-img">
    	                              <img src="../images/P-img3.jpg" />
    	                          </div>
    	                          <div className="question_answer_vote_section ">
    	                           <div className="arrows">
    	                                      <a href="" className="up_arrow"></a>
    	                                      <h6>33</h6>
    	                                      <a href="" className="down_arrow"></a>
    	                                  </div>
    	                          </div>
    	                          <div className="right-question-content " style={{float:'right'}}>
    	                              <div className="name-date">
    	                                  <div className="name-section">
    	                                      <h5>Mariam A</h5>
    	                                  </div>
    	                                  <div className="date-section">
    	                                      <h5>Fri 21 at 10.00 AM</h5>
    	                                  </div>

    	                              </div>
    	                              <div className="clear"></div>
    	                              
    	                                  <div className="question-details">
    	                                      <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ?</p>
    	                                  
    	                                 
    	                                  <div className="clear"></div>


    	                              </div>

    	                          </div>

    	                      </div>
    	                  </div>

    	                  <div className="clear"></div>
    	              </div>
    	          </div>

    	      </div>

    	  </section>
    	  <section id="answer-content">
    	      <div className="container">
    	          <div className="row">
    	              <div className=" col-xs-12 col-md-12 col-lg-12">
    	                  <div className="answer-section">
    	                      <div className=" col-xs-12 col-sm-6 col-md-6 answers-number">
    	                          <a href="#">REPLY</a>
    	                          <p> 4 Answers</p>
    	                      </div>
    	                      <div className=" col-xs-12 col-sm-6 col-md-6 sort-by-option">
    	                          <p>Sort By:<span>
    	                           
    	                      <select name="sort" id="sort" className="input-field" autoComplete="off">
    	                      <option value="" > Votes</option>
    	                       </select>
    	                              
    	                                  </span></p>

    	                      </div>
    	                  </div>
    	              </div>
    	          </div>

    	      </div>

    	  </section>
    	  <section id="answers-fullsection">
    	      <div className="container">
    	          <div className="row">
    	              <div className=" col-xs-12 col-md-12 col-lg-12">
    	                  <div className="answer-listing-section">

    	                      <ul className="parent-comment">
    	                          <li>
    	                              <div className="col-xs-12 col-sm-2 col-md-1">
    	                                  <div className="image-section">
    	                                      <img src="../images/P-img4.jpg" />
    	                                      <a href="" className="up_arrow"></a>
    	                                      <h6>3</h6>
    	                                      <a href="" className="down_arrow"></a>

    	                                  </div>
    	                              </div>
    	                              <div className="col-xs-12 col-sm-10 col-md-11">
    	                                  <div className="comment-content">
    	                                      <div className="name-date">
    	                                          <div className="name-section">
    	                                              <h5><a href="">Gabriel Brage</a> <span>Answered</span></h5>

    	                                          </div>

    	                                          <div className="date-section">
    	                                              <h5>Fri 21 at 10.00 AM</h5>
    	                                          </div>

    	                                      </div>
    	                                      <div className="clear"></div>
    	                                      <div className="comment-description">
    	                                          <h6>Hello Arian</h6>
    	                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

    	                                          <h6>Best Regards</h6>
    	                                          <h6>Gabriel Brage</h6>
    	                                      </div>

    	                                      <div className="relytocomment-section">
    	                                          <h6><a href="">2 Comments<span><img src="../images/comment-up-arrow.png" /></span></a></h6>
    	                                          <div className="reply-icon">
    	                                              
    	                                              <a href="" className="comment-btn" id="scroll">Comment</a>

    	                                          </div>


    	                                      </div>
    	                                      <div className="clear"></div>
    	                                  </div>

    	                              </div>
    	                              <div className="clear"></div>

    	                          </li>

    	                          <li>
    	                              <div className="col-xs-12 col-sm-2 col-md-1">
    	                                  <div className="image-section">
    	                                      <img src="../images/P-img4.jpg" />
    	                                      <a href="" className="up_arrow"></a>
    	                                      <h6>0</h6>
    	                                      <a href="" className="down_arrow"></a>

    	                                  </div>
    	                              </div>
    	                              <div className="col-xs-12 col-sm-10 col-md-11">
    	                                  <div className="comment-content">
    	                                      <div className="name-date">
    	                                          <div className="name-section">
    	                                              <h5><a href="">Sara Jacob</a> <span>Answered</span></h5>

    	                                          </div>

    	                                          <div className="date-section">
    	                                              <h5>Fri 21 at 10.00 AM</h5>
    	                                          </div>

    	                                      </div>
    	                                      <div className="clear"></div>
    	                                      <div className="comment-description">
    	                                          <h6>Hello Arian</h6>
    	                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

    	                                          <h6>Best Regards</h6>
    	                                          <h6>Sara Jacob</h6>
    	                                      </div>

    	                                      <div className="relytocomment-section">
    	                                          <h6><a href="" className="graycolor-comment">0 Comments<span><img src="../images/comment-up-arrow.png" /></span></a></h6>
    	                                          <div className="reply-icon">
    	                                             
    	                                              <a href="" className="comment-btn" id="scroll">Comment</a>
    	                                          </div>


    	                                      </div>
    	                                      <div className="clear"></div>
    	                                  </div>

    	                              </div>
    	                              <div className="clear"></div>
    	                          </li>
    	                          <li>
    	                              <div className="col-xs-12 col-sm-2 col-md-1">
    	                                  <div className="image-section">
    	                                      <img src="../images/P-img4.jpg" />
    	                                      <a href="" className="up_arrow"></a>
    	                                      <h6>1</h6>
    	                                      <a href="" className="down_arrow"></a>

    	                                  </div>
    	                              </div>
    	                              <div className="col-xs-12 col-sm-10 col-md-11">
    	                                  <div className="comment-content">
    	                                      <div className="name-date">
    	                                          <div className="name-section">
    	                                              <h5><a href="">Mehek</a> <span>Answered</span></h5>

    	                                          </div>

    	                                          <div className="date-section">
    	                                              <h5>Fri 21 at 10.00 AM</h5>
    	                                          </div>

    	                                      </div>
    	                                      <div className="clear"></div>
    	                                      <div className="comment-description">
    	                                          <h6>Hello Arian</h6>
    	                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

    	                                          <h6>Best Regards</h6>
    	                                          <h6>Arya Khana</h6>
    	                                      </div>

    	                                      <div className="relytocomment-section">
    	                                          <h6><a href="" className="graycolor-comment">0 Comments<span><img src="../images/comment-up-arrow.png" /></span></a></h6>
    	                                          <div className="reply-icon">
    	                                              
    	                                              <a href="" className="comment-btn" id="scroll">Comment</a>

    	                                          </div>


    	                                      </div>
    	                                      <div className="clear"></div>
    	                                  </div>

    	                              </div>
    	                              <div className="clear"></div>

    	                          </li>
    	                      </ul>



    	                  </div>



    	              </div>
    	          </div>
    	      </div>
    	  </section>



    	          <section id="custom-answers-section">
    	            <div className="container">
    	                <div className="row">
    	                    <div className=" col-xs-12 col-md-12 col-lg-12">
    	                        <div className="answer-message">
    	                            <h2>Answers</h2>

    	                            <div className=" col-xs-12 col-sm-2 col-md-1 left-img">
    	                                <img src="../images/P-img1.jpg" />
    	                            </div>
    	                            <div className=" col-xs-12 col-sm-10 col-md-11 right-content">

    	                                <form id="answer-form" name="form" action="" method="post" className="row">

    	                                    <div className="form-group">

    	                                        <textarea className="form-control summernote" rows="6" id="message" name="message" placeholder="Message" autoComplete="off"></textarea>
    	                                        <input type="file" name="fileToUpload" id="fileToUpload" />
    	                                       
    	                                        
    	                                        
    	                                        
    	                                        

    	                                        <div id="err1" className="err"></div>
    	                                    </div>
    	                                    <button type="sumbit" className="btn submit_btn">Submit</button>
    	                                    <div id="result"></div>
    	                                </form>

    	                            </div>
    	                        </div>
    	                    </div>
    	                </div>
    	            </div>
    	        </section>
    	  </div>
    	   );
  }
}

export default QuestionDetail;
