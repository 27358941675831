import React, { Component } from 'react';
import axios from 'axios';
import {withRouter} from 'react-router';
import QuestionRows from './QuestionRows';
import { Link } from "react-router-dom";
import { myConfig } from '../config'
class TopQuestions extends Component {
    constructor() {
        super();
        this.state = {
            data:
                [

                ],
            sort: 1

        }
       /* axios.get(`${myConfig.apiUrl}/questions/10`, {
            params: {
                sort: this.state.sort
            }
        })
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })*/
        this.handlesortChange = this.handlesortChange.bind(this);
    }
    componentDidMount() {
        if(this.props.questions){
            //console.log(this.props.questions);
            this.setState({data:this.props.questions})
          
          }
      }
      loadQuestions(){
          console.log("in reload");
        axios.get(`${myConfig.apiUrl}/questions/10`, {
          params: {
              sort: 1
          }
      })
          .then(res => {
              const data = res.data;
              this.setState({ data:data });
          })
      }
      routerWillLeave(nextState) { // return false to block navigation, true to allow
        if (nextState.action === 'POP') {
          // handle "Back" button clicks here
          console.log('component loaded1')
        }
      }
    componentWillReceiveProps(newProps) {    
      //  console.log('Component WILL RECIEVE PROPS!')
        //console.log(newProps)
        this.setState({data:newProps.questions})
      }
    handlesortChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value, activePage: 1
        });
        axios.get(`${myConfig.apiUrl}/questions/10`, {
            params: {
                sort: event.target.value
            }
        })
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })



    }
    onUpVote= (qn_id)=>
    {
        
        var userId = localStorage.getItem("user_id");
        if(userId)
            {
                //var url = `http://localhost:4000/like/question/${userId}/${qn_id}`;
                var url = `${myConfig.apiUrl}/like/question/${userId}/${qn_id}`;    
             axios.get(url)
                .then(res => {
                    const data = res.data;
                    const obj=this.state.data;
                    axios.get(`${myConfig.apiUrl}/questions/10`, {
                        params: {
                            sort: this.state.sort
                        }
                    })
                        .then(res => {
                            const data = res.data;
                            this.setState({ data });
                        })
                    
                    
                })
            }
        else
            {
            alert("You need to login to give your vote!");
            }
        
        
        
    }
 
    onDownVote= (qn_id)=>
    {
        
        var userId = localStorage.getItem("user_id");
        if(userId)
            {
            

                //var url = `http://localhost:4000/unlike/question/${userId}/${qn_id}`;
                var url = `${myConfig.apiUrl}/unlike/question/${userId}/${qn_id}`;
                
                    
             axios.get(url)
                .then(res => {
                    const data = res.data;
                    const obj=this.state.data;
                    axios.get(`${myConfig.apiUrl}/questions/10`, {
                        params: {
                            sort: this.state.sort
                        }
                    })
                        .then(res => {
                            const data = res.data;
                            this.setState({ data });
                        })
                    
                    
                })
            }
        else
            {
            alert("You need to login to give your vote!");
            }
        
        
        
    }
    render() {
        return (
            <div className=" col-xs-12 col-md-12 col-lg-8">
                <div className="left-question-section">
                    <h2>Latest Q & A</h2>
 
                    <div className="clearfix"></div>
                    <div className="totalquestion">
                        <p className="col-md-6 ">{this.state.data.length} <span className="question-section">Question</span></p>
                        <div className="col-md-6 sort-by-option">
                            <p>Sort By:<span>
                                <select name="sort" id="sort" className="input-field" value={this.state.sort} onChange={this.handlesortChange} autoComplete="off">
                                    <option value="1">Latest Questions</option>
                                    <option value="2">Popular Questions</option>
                                    <option value="3">Updated On</option>
                                    <option value="4">Latest Answered</option>
                                    <option value="5">Unanswered Questions</option>
                                </select>
                            </span></p>

                        </div>
                    </div>
                    {this.state.data.map((dtrow, i) => <QuestionRows key={i} onUpVote={this.onUpVote} onDownVote={this.onDownVote}
                        data={dtrow} />)}


                    <Link to="/searchresults"> <input type="button" className="questions-button" value="View all Questions & Answers" /></Link>

                </div>
            </div>

        );
    }
}

export default withRouter(TopQuestions);
