import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";




class EditProfile extends Component {
    constructor() {
        super();
        this.state = {
            data:
                [

                ],
            ctab: 1


        }
        var user_id = localStorage.getItem('user_id');
        axios.get(`http://192.168.1.16:4000/user/${user_id}`)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })
        this.changeTab = this.changeTab.bind(this);
    }
    changeTab(value) {
        console.log(value)
        this.setState({ ctab: value })
    }
    convertDate(date) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var curday = new Date(date);
        return curday.toLocaleDateString("en-US", options);
    }
    render() {
   
        return (
            <section id="profile-section">
            <div className="container">
                <div className="row">
                <div className=" col-xs-12 col-md-12 col-lg-12"></div>
					<div className="profile-listing">
                    <div className="profile-setting-section">
							<h2>Profile</h2>
							<div ng-if="ctrl.pUser == ctrl.CUser"
								className="settings-image-section">
								<a ng-click="ctrl.editProfile()"> <span
									className="glyphicon glyphicon-pencil"></span>
								</a>

							</div>
						</div>
						<div className="clear"></div>
						<div className="profile-custom-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div className="profile-fullcontent" style={{marginTop: '-25px'}}>
                <div className="profile-image-section">

                         <img
                        src="images/P-img1.jpg" 
                        id="profileimage" />

                    <div ng-if="ctrl.pUser == ctrl.CUser && ctrl.editUser==2"
                        className="edit">
                        <a ng-click="ctrl.uploadProfilePic($event)"><i
                            className="fa fa-pencil fa-lg"></i></a>
                    </div>
                    <div ng-show="ctrl.profilepic==true" className="form-group">

                        <input id="profilefile-upload" name="profilefile-upload"
                             accept=".jpg,.jpeg,.gif,.png"
                            
                            type="file" />
                    </div>

                </div>
                <div className="profile-details">
                    <h3 ng-show="ctrl.editUser==1">Fn</h3>

                    <input type="text" ng-show="ctrl.editUser==2"
                        ng-required="true" placeholder="FirstName"
                        value="" style={{width: '80%'}}
                        className="form-control" ng-model="userprofile.FirstName"
                        id="FirstName" name="FirstName" maxlength="100" tabindex="1" />

                    <input type="text" ng-show="ctrl.editUser==2"
                        ng-required="true" value=""
                        style={{width: '80%', marginTop: '10px' }} className="form-control"
                        ng-model="userprofile.LastName" placeholder="LastName"
                        id="LastName" name="LastName" maxlength="100" tabindex="1" />

       

                    <p className="duration">
                        <i>Member since </i>
                    </p>

                    <div >
                        <h6
                            style={{color: '#000', fontSize: '14px', fontFamily: 'Dupla'}}>Organization</h6>
                      
                        <input type="text" 
                            style={{width: '80%'}} className="form-control"
                             id="OrgName" name="OrgName"
                            maxlength="100" placeholder="Organization Name" tabindex="1" />
                    </div>


                    <input ng-show="ctrl.editUser==2" ng-click="updateProfile()"
                        className="read-more-button" value="Update" type="button" />


                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </section>
        );
    }
}

export default EditProfile;
