import React, { Component } from 'react';
import axios from 'axios';
import QuestionRows from './QuestionRows';
import ResultRow from './ResultRow';
import { Link, withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import { myConfig } from '../config'

class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:
                [

                ]
            ,
            search: '',
            activePage: 1,
            itemsperPage: 3,
            sort: 1
        }

        var search_key = this.props.match.params.search_key
        if (search_key) {
            this.state.search = search_key;
            axios.get(`${myConfig.apiUrl}/search/${search_key}`, {
                params: {
                    sort: this.state.sort
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }
        else {
            axios.get(`${myConfig.apiUrl}/search`, {
                params: {
                    sort: this.state.sort
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleselectChange = this.handleselectChange.bind(this);
        this.handlesortChange = this.handlesortChange.bind(this);
        this.handlesearchClick = this.handlesearchClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }
    
    onUpVote= (qn_id)=>
    {
        
        var userId = localStorage.getItem("user_id");
        if(userId)
            {
                //var url = `http://localhost:4000/like/question/${userId}/${qn_id}`;
                var url = `${myConfig.apiUrl}/like/question/${userId}/${qn_id}`;    
             axios.get(url)
                .then(res => {
                    const data = res.data;
                    const obj=this.state.data;
                    if (this.state.search!='') {
                        var search_key=this.state.search;
                        axios.get(`${myConfig.apiUrl}/search/${search_key}`, {
                            params: {
                                sort: this.state.sort
                            }
                        })
                            .then(res => {
                                const data = res.data;
                                this.setState({ data });
                            })
                    }
                    else {
                        axios.get(`${myConfig.apiUrl}/search`, {
                            params: {
                                sort: this.state.sort
                            }
                        })
                            .then(res => {
                                const data = res.data;
                                this.setState({ data });
                            })
                    }
                    
                    
                })
            }
        else
            {
            alert("You need to login to give your vote!");
            }
        
        
        
    }
 
    onDownVote= (qn_id)=>
    {
        
        var userId = localStorage.getItem("user_id");
        if(userId)
            {
            

                //var url = `http://localhost:4000/unlike/question/${userId}/${qn_id}`;
                var url = `${myConfig.apiUrl}/unlike/question/${userId}/${qn_id}`;
                
                    
             axios.get(url)
                .then(res => {
                    const data = res.data;
                    const obj=this.state.data;
                    if (this.state.search!='') {
                        var search_key=this.state.search;
                        axios.get(`${myConfig.apiUrl}/search/${search_key}`, {
                            params: {
                                sort: this.state.sort
                            }
                        })
                            .then(res => {
                                const data = res.data;
                                this.setState({ data });
                            })
                    }
                    else {
                        axios.get(`${myConfig.apiUrl}/search`, {
                            params: {
                                sort: this.state.sort
                            }
                        })
                            .then(res => {
                                const data = res.data;
                                this.setState({ data });
                            })
                    }
                    
                    
                })
            }
        else
            {
            alert("You need to login to give your vote!");
            }
        
        
        
    }
    handleTextChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });

    }
    handleselectChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value, activePage: 1
        });

    }
    handlesortChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value, activePage: 1
        });
        var search_key = this.props.match.params.search_key

        if (search_key) {
            console.log(search_key)
            axios.get(`${myConfig.apiUrl}/search/${search_key}`, {
                params: {
                    sort: event.target.value
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }
        else {
            axios.get(`${myConfig.apiUrl}/search`, {
                params: {
                    sort: event.target.value
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }

    }
    handlesearchClick(event) {

        event.preventDefault();

        let path = `/searchresults/${this.state.search}`;
        this.props.history.push(path);
        console.log(this.state);
        var search_key = this.props.match.params.search_key
        if (this.state.search) {

            axios.get(`${myConfig.apiUrl}/search/${this.state.search}`, {
                params: {
                    sort: this.state.sort
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }
        else {
            axios.get(`${myConfig.apiUrl}/search`, {
                params: {
                    sort: this.state.sort
                }
            })
                .then(res => {
                    const data = res.data;
                    this.setState({ data });
                })
        }



    }
    handleKeyPress = (event) => {

        if (event.key == 'Enter') {
            event.preventDefault()
            let path = `/searchresults/${this.state.search}`;
            this.props.history.push(path);
            console.log(this.state);
            var search_key = this.props.match.params.search_key
            if (this.state.search) {

                axios.get(`${myConfig.apiUrl}/search/${this.state.search}`, {
                    params: {
                        sort: this.state.sort
                    }
                })
                    .then(res => {
                        const data = res.data;
                        this.setState({ data });
                    })
            }
            else {
                axios.get(`${myConfig.apiUrl}/search`, {
                    params: {
                        sort: this.state.sort
                    }
                })
                    .then(res => {
                        const data = res.data;
                        this.setState({ data });
                    })
            }


        }
    }
    pageReload(){
        window.location.reload()
    }
    render() {
        return (
            <section>
                &nbsp;
		<div className="container">
                    <div className="latest-top-ranked">
                        <div className="ranked-section">
                            <div className="clearfix"></div>

                            <section id="find-questions-section" style={{ boxShadow: 'none', WebkitBoxShadow: 'none' }}>
                                <h2>Questions & Answers</h2>
                                <div className="search-container">
                                    <form id="question-form">

                                        <div
                                            className="form-group col-xs-12 col-md-4 col-md-offset-4 find-question">
                                            <input type="text" className="search-button" onKeyPress={this.handleKeyPress}
                                                value={this.state.search} onChange={this.handleTextChange} 
                                                placeholder="Find questions , answers etc" name="search" />
                                            <button value="button" type="button" className="search_button" onClick={this.handlesearchClick}
                                            >
                                                <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                        <div className="col-md-2">
                                            <button value="button" className="signupbtn" type="button" onClick={this.pageReload}
                                                style={{ width: '25%', paddingLeft: 0, paddingRight: '10px', float: 'left' }}
                                            >
                                                <span className="glyphicon glyphicon-refresh"></span>
                                            </button>
                                        </div>
                                        <div className="col-md-4 sort-by-option">
                                            <p>
                                                Sort By:<span> <select name="sort" id="sort"
                                                    name="sort" className="input-field" autoComplete="off"
                                                    onChange={this.handlesortChange} value={this.state.sort}>
                                                    <option value="1">Latest Questions</option>
                                                    <option value="2">Popular Questions</option>
                                                    <option value="3">Updated On</option>
                                                    <option value="4">Latest Answered</option>
                                                    <option value="5">Unanswered Questions</option>
                                                </select>
                                                </span>
                                            </p>

                                        </div>
                                    </form>
                                </div>
                            </section>
                            <hr />
                            <section>
                                <div className="col-md-12">



                                </div>
                                {(this.state.data.length === 0) ? (
                                    <div style={{ textAlign: 'center', marginTop: '15px' }}
                                    >
                                        <b>No Results Found</b>
                                    </div>
                                ) : (null)}
                                <div>

                                    {this.state.data.map((dtrow, i) => <ResultRow key={i} onUpVote={this.onUpVote} onDownVote={this.onDownVote}
                                        data={dtrow} index={i} itemsperPage={this.state.itemsperPage} currentPage={this.state.activePage} />)}

                                    <div className="clearfix"></div>
                                </div>
                                <div className="col-md-5" style={{ marginTop: '20px' }}>
                                    <span style={{ paddingLeft: '25px' }}><label>View </label> <select
                                        className="input-field" value={this.itemsperPage} name="itemsperPage" onChange={this.handleselectChange}><option>3</option>
                                        <option>5</option>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option></select> questions at a time. </span>
                                </div>
                                <div className="col-md-7">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsperPage}
                                        totalItemsCount={this.state.data.length}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                                <div className="clearfix"></div>

                                <br />
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(SearchResults);
