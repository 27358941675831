import React, { Component } from 'react';
import axios from 'axios';

import 'font-awesome/css/font-awesome.min.css';
import UserqnRows from './UserqnRows';
import UseransRows from './UseransRows';
import { myConfig } from '../config'

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:
                [

                ],
            ctab: 1,
            editField: false,
            editFile: false,
            FirstName: '',
            LastName: '',
            OrgName: '',
            MobileNo: '',
            selectedFile: null, loaded: 0,
            qnData: [],
            ansData: [],
            cuser_id: 0,
            open:false
        }

        var user_id = this.props.match.params.user_id

        this.state.cuser_id = user_id;
        axios.get(`${myConfig.apiUrl}/user/${user_id}`)
            .then(res => {
                const data = res.data;

                this.setState({ data });
                this.setState({ FirstName: data.first_name, LastName: data.last_name, OrgName: data.organization, MobileNo: data.mobile_no });

            })
        axios.get(`${myConfig.apiUrl}/user_questions/${user_id}`)
            .then(res => {
                const data1 = res.data;

                this.setState({ qnData: data1 });


            })
        axios.get(`${myConfig.apiUrl}/user_answers/${user_id}`)
            .then(res => {
                const data2 = res.data;

                this.setState({ ansData: data2 });


            })
        this.changeTab = this.changeTab.bind(this);
        this.editClick = this.editClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.handleselectedFile = this.handleselectedFile.bind(this);

    }
    handleClickOpen = () => {
        this.setState({
          open: true,
        });
      };
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    }
    updateProfile() {
        var apiBaseUrl = myConfig.apiUrl;
        var self = this;

        var payload = "email=" + this.state.email + "&password=" + this.state.password + "&fullname=" + this.state.fullname;

        const data = new FormData()
        if (this.state.selectedFile)
            data.append('profile_photo', this.state.selectedFile, this.state.selectedFile.name)
        data.append('fullname', this.state.FirstName + ' ' + this.state.LastName)
        data.append('first_name', this.state.FirstName)
        data.append('last_name', this.state.LastName)

        data.append('organization', this.state.OrgName)
        //data.append('address', this.state.message)
        //data.append('city', this.state.message)
        //data.append('state', this.state.message)
        //data.append('country', this.state.message)
        //data.append('zipcode', this.state.message)
        data.append('mobile_no', this.state.MobileNo)


        data.append('user_id', localStorage.getItem("user_id"))
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        var self = this;
        axios.post(apiBaseUrl + 'myaccount', data, config)
            .then(function (response) {


                if (response.data.status == true) {
                    alert("Profile updated successfully");

                    var user_id = localStorage.getItem('user_id');
                    axios.get(`${myConfig.apiUrl}/user/${user_id}`)
                        .then(res => {
                            const data = res.data;

                            self.setState({ data });
                            self.setState({ FirstName: data.first_name, LastName: data.last_name, OrgName: data.organization, MobileNo: data.mobile_no });
                            self.setState({ editField: false })
                        })

                    //self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                }
                else {

                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    changeTab(value) {

        this.setState({ ctab: value })
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    editClick(value) {
        if (value === 2)
            this.setState({ editField: !this.state.editField })

    }
    convertDate(date) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var curday = new Date(date);
        return curday.toLocaleDateString("en-US", options);
    }
    render() {

        var tabData = null
        if (this.state.ctab === 1) {
            tabData = <div>
                {this.state.qnData.length === 0 ?
                    (
                        <div >
                            <div className="answer-listing-section question_inner_container">
                                <ul className="parent-comment myUl">
                                    <li className="myUl">

                                        <div className="col-xs-12 col-sm-10 col-md-11">
                                            <div className="comment-content">


                                                <div className="comment-description font_bold">
                                                    <p className="ng-binding" style={{ textAlign: 'center' }}>Not
                                        yet asked any Questions</p>
                                                </div>



                                            </div>

                                        </div>
                                        <div className="clear"></div>

                                    </li>
                                </ul>
                            </div>
                        </div>)
                    :
                    (
                        <div>
                            {this.state.qnData.map((dtrow, i) => <UserqnRows key={i}
                                data={dtrow} index={i} />)}
                        </div>
                    )
                }


            </div>
        }
        else if (this.state.ctab === 2) {
            tabData = <div>
                {this.state.ansData.length === 0 ?
                    (
                        <div >
                            <div className="answer-listing-section question_inner_container">
                                <ul className="parent-comment myUl">
                                    <li className="myUl">
                                        <div className="col-xs-12 col-sm-10 col-md-11">
                                            <div className="comment-content">
                                                <div className="comment-description font_bold">
                                                    <p className="ng-binding" style={{ textAlign: 'center' }}>Not
															yet Answered any Questions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) :
                    (
                        <div>
                            {this.state.ansData.map((dtrow, i) => <UseransRows key={i}
                                data={dtrow} index={i} />)}
                        </div>
                    )
                }

            </div>
        }
        else if (this.state.ctab === 3) {
            tabData = <div>
                <div className="answer-listing-section question_inner_container">
                    <div style={{ marginLeft: '10%' }}>
                        <b>Badges –</b> <br /> <br />

                        <div >
                            <p className="ng-binding" style={{ textAlign: 'center' }}>No Badges</p>
                        </div>





                        <a ng-repeat="Ubadge in ctrl.badgesList" href="#!/questionanswers">

                            <img
                                src="" />
                        </a>



                    </div>
                    <br /> <br />
                    <div className="clear"></div>
                </div>





            </div>

        }

        return (

            <div>
                <section id="profile-section">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12 col-md-12 col-lg-12">
                                <div className="profile-listing">
                                    <div className="profile-setting-section">
                                        <h2>Profile</h2>
                                        {
                                            (this.state.cuser_id == localStorage.getItem('user_id')) ?
                                                (
                                                    <div
                                                        className="settings-image-section">
                                                        <a onClick={() => this.editClick(2)}> <span
                                                            className="glyphicon glyphicon-pencil"></span>
                                                        </a>

                                                    </div>
                                                ) : (null)
                                        }

                                    </div>
                                    <div className="clear"></div>
                                    <div className="profile-custom-content">

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                            <div className="profile-fullcontent">
                                                <div className="profile-image-section">
                                                    <img src={this.state.data.profile_photo != null ? myConfig.apiUrl+"/get_file/" + this.state.data.profile_photo : "../images/defpic.png"} />
                                                </div>
                                                {(this.state.cuser_id == localStorage.getItem('user_id')) ?
                                                (
                                                    <div>
                                                        <div
                                                            className="edit">
                                                            <a onClick={() => this.editClick(1)}><i
                                                                className="fa fa-pencil fa-lg"></i></a>
                                                        </div>
                                                        <div className="form-group">

                                                            <input id="profilefile-upload" name="profilefile-upload"
                                                                onChange={this.handleselectedFile} accept=".jpg,.jpeg,.gif,.png"
                                                                style={{ display: 'none' }}
                                                                type="file" />
                                                        </div>
                                                    </div>
                                                ) : (null)
                                                }
                                                <div className="profile-details">
                                                    <h3>{this.state.data.fullname}</h3>
                                                    {this.state.editField ? (

                                                        <div>
                                                            <input type="text"
                                                                placeholder="FirstName"
                                                                style={{ width: '80%' }}
                                                                className="form-control"
                                                                id="FirstName" onChange={this.handleInputChange} name="FirstName" value={this.state.FirstName} maxLength="100" tabIndex="1" />

                                                            <input type="text"

                                                                style={{ width: '80%', marginTop: '10px' }} className="form-control"
                                                                placeholder="LastName"
                                                                id="LastName" onChange={this.handleInputChange} name="LastName" value={this.state.LastName} maxLength="100" tabIndex="1" />
                                                        </div>
                                                    ) : (
                                                            null
                                                        )}

                                                    <p className="duration"><i>Member since {this.convertDate(this.state.data.date_added)}</i></p>
                                                    <div >
                                                        <h6
                                                            style={{ color: '#000', fontSize: '14px', fontFamily: 'Dupla' }}>Organization</h6>
                                                        {!this.state.editField ? (
                                                            <h6 className="graycolor-address"
                                                                style={{ fontSize: '14px', fontFamily: 'Dupla' }}>{this.state.data.organization}</h6>
                                                        ) : (
                                                                null
                                                            )}
                                                        {this.state.editField ? (
                                                            <div>
                                                                <input type="text"

                                                                    style={{ width: '80%' }} className="form-control"
                                                                    id="OrgName" name="OrgName" onChange={this.handleInputChange}
                                                                    maxLength="100" placeholder="Organization Name" tabIndex="1" value={this.state.OrgName} />
                                                                <input ng-show="ctrl.editUser==2" onClick={this.updateProfile}
                                                                    className="read-more-button" value="Update" type="button" />
                                                            </div>
                                                        ) : (
                                                                null
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                                            <div className="col-xs-12 col-sm-12 col-md-12 address-details">

                                                <ul>
                                                    <li>
                                                        <h6>Email Id</h6>
                                                        <h6 className="graycolor-address">{this.state.data.email_id}</h6>
                                                    </li>
                                                    <li>
                                                        <h6>Mobile No</h6>
                                                        {!this.state.editField ? (
                                                            <h6 className="graycolor-address">{this.state.data.mobile_no}</h6>
                                                        ) : (null)
                                                        }
                                                        {this.state.editField ? (
                                                            <input type="text"
                                                                value={this.state.MobileNo}
                                                                style={{ width: '90%' }} className="form-control"
                                                                onChange={this.handleInputChange} id="MobileNo" name="MobileNo"
                                                                placeholder="MobileNo" maxLength="100" tabIndex="1" />
                                                        ) : (
                                                                null
                                                            )}
                                                    </li>

                                                </ul>



                                            </div>

                                           


                                        </div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                <section id="profile-menu">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12 col-md-12 col-lg-12">
                                <div id="tab">
                                    <ul className="profile-menu-content resp-tabs-list">

                                        <li onClick={() => this.changeTab(1)} id="tab1" style={{ cursor: 'pointer' }} className={(this.state.ctab == 1) ? 'profile-detail-menu resp-tab-item resp-tab-active' : 'profile-detail-menu resp-tab-item'}>Questions
                                   </li>

                                        <li id="tab2" onClick={() => this.changeTab(2)} style={{ cursor: 'pointer' }} className={(this.state.ctab == 2) ? 'profile-detail-menu resp-tab-item resp-tab-active' : 'profile-detail-menu resp-tab-item'}>Reply
                                   </li>
                                       
                                    </ul>
                                    <div className="resp-tabs-container">

                                        {tabData}






                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}

export default UserDetails;
